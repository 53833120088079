const v1_prescriptions: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			width='512px'
			height='512px'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd'
			}}
			viewBox='0 0 512 512'
			{...props}
			xmlnsXlink='http://www.w3.org/1999/xlink'>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31c9cf'
					d='M 215.5,23.5 C 215.5,36.8333 215.5,50.1667 215.5,63.5C 199.5,63.5 183.5,63.5 167.5,63.5C 167.5,108.833 167.5,154.167 167.5,199.5C 156.833,199.5 146.167,199.5 135.5,199.5C 135.333,142.499 135.5,85.499 136,28.5C 137.167,26.6667 138.667,25.1667 140.5,24C 165.498,23.5 190.498,23.3334 215.5,23.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fcbf44'
					d='M 215.5,23.5 C 274.167,23.5 332.833,23.5 391.5,23.5C 391.5,36.8333 391.5,50.1667 391.5,63.5C 391.87,69.9251 391.37,76.2584 390,82.5C 388.661,85.0943 386.494,86.5943 383.5,87C 329.5,87.6667 275.5,87.6667 221.5,87C 218.337,85.5202 216.504,83.0202 216,79.5C 215.501,74.1771 215.334,68.8437 215.5,63.5C 215.5,50.1667 215.5,36.8333 215.5,23.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31c9cf'
					d='M 391.5,23.5 C 416.502,23.3334 441.502,23.5 466.5,24C 468.333,25.1667 469.833,26.6667 471,28.5C 471.667,179.833 471.667,331.167 471,482.5C 469.833,484.333 468.333,485.833 466.5,487C 395.167,487.5 323.834,487.667 252.5,487.5C 257.156,486.499 261.823,485.166 266.5,483.5C 272.007,481.323 276.674,477.99 280.5,473.5C 288.086,466.328 293.086,457.661 295.5,447.5C 343.5,447.5 391.5,447.5 439.5,447.5C 439.5,319.5 439.5,191.5 439.5,63.5C 423.5,63.5 407.5,63.5 391.5,63.5C 391.5,50.1667 391.5,36.8333 391.5,23.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fefcf7'
					d='M 239.5,47.5 C 244.833,47.5 250.167,47.5 255.5,47.5C 255.5,52.8333 255.5,58.1667 255.5,63.5C 250.167,63.5 244.833,63.5 239.5,63.5C 239.5,58.1667 239.5,52.8333 239.5,47.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fefcf7'
					d='M 271.5,47.5 C 292.833,47.5 314.167,47.5 335.5,47.5C 335.5,52.8333 335.5,58.1667 335.5,63.5C 314.167,63.5 292.833,63.5 271.5,63.5C 271.5,58.1667 271.5,52.8333 271.5,47.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fefcf7'
					d='M 351.5,47.5 C 356.833,47.5 362.167,47.5 367.5,47.5C 367.5,52.8333 367.5,58.1667 367.5,63.5C 362.167,63.5 356.833,63.5 351.5,63.5C 351.5,58.1667 351.5,52.8333 351.5,47.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#d5f3f5'
					d='M 215.5,63.5 C 215.334,68.8437 215.501,74.1771 216,79.5C 216.504,83.0202 218.337,85.5202 221.5,87C 275.5,87.6667 329.5,87.6667 383.5,87C 386.494,86.5943 388.661,85.0943 390,82.5C 391.37,76.2584 391.87,69.9251 391.5,63.5C 407.5,63.5 423.5,63.5 439.5,63.5C 439.5,191.5 439.5,319.5 439.5,447.5C 391.5,447.5 343.5,447.5 295.5,447.5C 297.055,416.568 282.722,398.068 252.5,392C 244.13,390.909 236.13,392.076 228.5,395.5C 223.693,397.403 219.36,400.07 215.5,403.5C 215.667,372.832 215.5,342.165 215,311.5C 210,306.5 205,301.5 200,296.5C 199.5,285.505 199.333,274.505 199.5,263.5C 204.08,263.887 208.414,263.053 212.5,261C 213.717,259.352 214.55,257.519 215,255.5C 215.667,239.167 215.667,222.833 215,206.5C 214.027,202.594 211.527,200.427 207.5,200C 194.171,199.5 180.837,199.333 167.5,199.5C 167.5,154.167 167.5,108.833 167.5,63.5C 183.5,63.5 199.5,63.5 215.5,63.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#32cad0'
					d='M 294.5,119.5 C 323.613,117.794 342.446,130.794 351,158.5C 353.591,185.828 342.091,204.328 316.5,214C 285,218.999 264.833,206.166 256,175.5C 254.219,146.715 267.052,128.048 294.5,119.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#f6fdfc'
					d='M 295.5,143.5 C 300.833,143.5 306.167,143.5 311.5,143.5C 311.5,148.833 311.5,154.167 311.5,159.5C 316.833,159.5 322.167,159.5 327.5,159.5C 327.5,164.833 327.5,170.167 327.5,175.5C 322.167,175.5 316.833,175.5 311.5,175.5C 311.5,180.833 311.5,186.167 311.5,191.5C 306.167,191.5 300.833,191.5 295.5,191.5C 295.5,186.167 295.5,180.833 295.5,175.5C 290.167,175.5 284.833,175.5 279.5,175.5C 279.5,170.167 279.5,164.833 279.5,159.5C 284.833,159.5 290.167,159.5 295.5,159.5C 295.5,154.167 295.5,148.833 295.5,143.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 0.999 }}
					fill='#fcbf44'
					d='M 135.5,199.5 C 146.167,199.5 156.833,199.5 167.5,199.5C 180.837,199.333 194.171,199.5 207.5,200C 211.527,200.427 214.027,202.594 215,206.5C 215.667,222.833 215.667,239.167 215,255.5C 214.55,257.519 213.717,259.352 212.5,261C 208.414,263.053 204.08,263.887 199.5,263.5C 151.5,263.5 103.5,263.5 55.5,263.5C 51.8183,263.665 48.1516,263.499 44.5,263C 42.6667,261.833 41.1667,260.333 40,258.5C 39.3333,240.5 39.3333,222.5 40,204.5C 41.1667,202.667 42.6667,201.167 44.5,200C 74.8315,199.5 105.165,199.333 135.5,199.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 239.5,255.5 C 279.5,255.5 319.5,255.5 359.5,255.5C 359.5,260.833 359.5,266.167 359.5,271.5C 319.5,271.5 279.5,271.5 239.5,271.5C 239.5,266.167 239.5,260.833 239.5,255.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 375.5,255.5 C 388.833,255.5 402.167,255.5 415.5,255.5C 415.5,260.833 415.5,266.167 415.5,271.5C 402.167,271.5 388.833,271.5 375.5,271.5C 375.5,266.167 375.5,260.833 375.5,255.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#e64b30'
					d='M 55.5,263.5 C 103.5,263.5 151.5,263.5 199.5,263.5C 199.333,274.505 199.5,285.505 200,296.5C 205,301.5 210,306.5 215,311.5C 215.5,342.165 215.667,372.832 215.5,403.5C 215.167,404.167 214.833,404.833 214.5,405.5C 204.79,414.43 199.79,425.597 199.5,439C 199.813,453.452 205.147,465.619 215.5,475.5C 215.554,481.425 212.887,485.425 207.5,487.5C 153.166,487.667 98.8323,487.5 44.5,487C 42.6667,485.833 41.1667,484.333 40,482.5C 39.3333,425.167 39.3333,367.833 40,310.5C 45.0354,305.297 50.202,300.297 55.5,295.5C 92.8333,295.5 130.167,295.5 167.5,295.5C 167.5,290.167 167.5,284.833 167.5,279.5C 130.167,279.5 92.8333,279.5 55.5,279.5C 55.5,274.167 55.5,268.833 55.5,263.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#cb432a'
					d='M 55.5,279.5 C 92.8333,279.5 130.167,279.5 167.5,279.5C 167.5,284.833 167.5,290.167 167.5,295.5C 130.167,295.5 92.8333,295.5 55.5,295.5C 55.5,290.167 55.5,284.833 55.5,279.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 239.5,295.5 C 255.5,295.5 271.5,295.5 287.5,295.5C 287.5,300.833 287.5,306.167 287.5,311.5C 271.5,311.5 255.5,311.5 239.5,311.5C 239.5,306.167 239.5,300.833 239.5,295.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 303.5,295.5 C 340.833,295.5 378.167,295.5 415.5,295.5C 415.5,300.833 415.5,306.167 415.5,311.5C 378.167,311.5 340.833,311.5 303.5,311.5C 303.5,306.167 303.5,300.833 303.5,295.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 255.5,335.5 C 290.167,335.5 324.833,335.5 359.5,335.5C 359.5,340.833 359.5,346.167 359.5,351.5C 324.833,351.5 290.167,351.5 255.5,351.5C 255.5,346.167 255.5,340.833 255.5,335.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 375.5,335.5 C 388.833,335.5 402.167,335.5 415.5,335.5C 415.5,340.833 415.5,346.167 415.5,351.5C 402.167,351.5 388.833,351.5 375.5,351.5C 375.5,346.167 375.5,340.833 375.5,335.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fefcfb'
					d='M 111.5,343.5 C 122.167,343.5 132.833,343.5 143.5,343.5C 143.5,354.167 143.5,364.833 143.5,375.5C 154.167,375.5 164.833,375.5 175.5,375.5C 175.5,386.167 175.5,396.833 175.5,407.5C 164.833,407.5 154.167,407.5 143.5,407.5C 143.5,418.167 143.5,428.833 143.5,439.5C 132.833,439.5 122.167,439.5 111.5,439.5C 111.5,428.833 111.5,418.167 111.5,407.5C 100.833,407.5 90.1667,407.5 79.5,407.5C 79.5,396.833 79.5,386.167 79.5,375.5C 90.1667,375.5 100.833,375.5 111.5,375.5C 111.5,364.833 111.5,354.167 111.5,343.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#31cacf'
					d='M 303.5,375.5 C 340.833,375.5 378.167,375.5 415.5,375.5C 415.5,380.833 415.5,386.167 415.5,391.5C 378.167,391.5 340.833,391.5 303.5,391.5C 303.5,386.167 303.5,380.833 303.5,375.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fcbf44'
					d='M 295.5,447.5 C 293.086,457.661 288.086,466.328 280.5,473.5C 262.673,447.852 245.34,421.852 228.5,395.5C 236.13,392.076 244.13,390.909 252.5,392C 282.722,398.068 297.055,416.568 295.5,447.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#cc9a37'
					d='M 228.5,395.5 C 245.34,421.852 262.673,447.852 280.5,473.5C 276.674,477.99 272.007,481.323 266.5,483.5C 249.66,457.148 232.327,431.148 214.5,405.5C 214.833,404.833 215.167,404.167 215.5,403.5C 219.36,400.07 223.693,397.403 228.5,395.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#fcbe44'
					d='M 214.5,405.5 C 232.327,431.148 249.66,457.148 266.5,483.5C 261.823,485.166 257.156,486.499 252.5,487.5C 248.5,487.5 244.5,487.5 240.5,487.5C 231.312,485.407 222.979,481.407 215.5,475.5C 205.147,465.619 199.813,453.452 199.5,439C 199.79,425.597 204.79,414.43 214.5,405.5 Z'
				/>
			</g>
			<g>
				<path
					style={{ opacity: 1 }}
					fill='#45c6c8'
					d='M 215.5,475.5 C 222.979,481.407 231.312,485.407 240.5,487.5C 229.5,487.5 218.5,487.5 207.5,487.5C 212.887,485.425 215.554,481.425 215.5,475.5 Z'
				/>
			</g>
		</svg>
	);
};
export default v1_prescriptions;
