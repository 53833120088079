import { useEffect, useRef, useState } from 'react';

const LottiePlayer = ({ animationData, loop = true, autoplay = true, width = '100%', height = '100%' }) => {
	const container = useRef(null);
	const [animation, setAnimation] = useState(null);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const lottie = require('lottie-web');

			// Initialize the animation
			const anim = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				loop: loop,
				autoplay: autoplay,
				animationData: animationData
			});

			setAnimation(anim);

			return () => {
				anim.destroy();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // This effect initializes the animation once

	useEffect(() => {
		if (typeof window !== undefined) {
			if (animation) {
				animation.stop();
				animation.destroy();
				// eslint-disable-next-line @typescript-eslint/no-var-requires
				const lottie = require('lottie-web');
				const newAnimation = lottie.loadAnimation({
					container: container.current,
					renderer: 'svg',
					loop: loop,
					autoplay: autoplay,
					animationData: animationData
				});

				setAnimation(newAnimation);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [animationData]); // This effect watches for changes in animationData prop

	return <div ref={container} style={{ width, height }} />;
};

export default LottiePlayer;
