const v1_breastfeeding: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='76' height='75' viewBox='0 0 76 75' fill='none'>
			<rect opacity='0.1' x='0.109375' width='75.3876' height='75' rx='37.5' fill='#0E7673' />
			<path
				d='M20.3958 29.7349C20.3958 29.7349 24.4446 28.2246 27.6975 27.4329C30.9504 26.6411 34.058 25.3466 35.4329 23.1891C36.8057 21.0317 39.4817 16.4298 39.048 14.199C38.6143 11.9703 36.0835 11.2497 32.2538 14.3435C28.4218 17.4352 23.5793 24.1233 21.8444 26.4253C20.1095 28.7273 19.6042 29.5903 20.398 29.7327L20.3958 29.7349Z'
				fill='#808080'
			/>
			<path
				d='M32.107 15.9998C32.107 15.9998 29.793 17.9415 27.1907 19.5963C24.5884 21.251 26.6117 24.9899 29.5046 25.3502C32.3954 25.7105 33.046 24.2715 33.6965 21.6113C34.3471 18.9512 34.4187 15.2835 33.625 15.4259C32.8291 15.5704 32.107 16.0019 32.107 16.0019V15.9998Z'
				fill='#263238'
			/>
			<path
				d='M33.4818 19.5282C33.4818 19.5282 32.9396 21.9014 31.9638 23.4116C30.9879 24.9218 29.9036 25.677 25.8917 26.6478C21.8797 27.6187 21.3376 27.8344 21.0123 30.3155C20.687 32.7966 22.964 44.8784 22.964 44.8784L23.9399 53.4004C23.9399 53.4004 28.6024 54.8027 34.1324 54.8027C39.6623 54.8027 43.7827 53.8318 44.108 53.724C44.4333 53.6161 52.1319 51.135 52.7824 50.5957C53.433 50.0563 54.7342 48.8697 54.7342 48.8697C54.7342 48.8697 49.9632 41.8579 48.9874 38.9454C48.0115 36.0328 46.1682 30.5312 44.4333 28.0502C42.6984 25.5691 39.4455 24.4904 38.7949 23.951C38.1443 23.4116 35.6504 20.9305 35.2167 20.0676C34.783 19.2046 33.4818 19.5282 33.4818 19.5282Z'
				fill='#F7C5B4'
			/>
			<path
				d='M32.9267 22.3723C32.9267 22.3723 33.2281 25.1532 33.985 27.3323C34.7418 29.5113 36.1753 30.3376 36.1753 30.3376L37.9123 28.9849C37.9123 28.9849 36.93 26.9569 35.6461 24.9267C34.3623 22.8987 33.6966 21.6107 33.6966 21.6107C33.6966 21.6107 32.8508 21.5438 32.9267 22.3701V22.3723Z'
				fill='#263238'
			/>
			<path
				d='M44.6453 36.8613C44.6453 36.8613 46.0961 38.8656 46.1828 40.5592C46.2696 42.2528 43.2357 47.6487 40.9587 48.188C38.6816 48.7274 36.4046 44.4125 37.3804 41.0684C38.3563 37.7243 42.7044 34.5658 44.6453 36.8613Z'
				fill='#263238'
			/>
			<path
				d='M44.866 35.9278C44.866 35.9278 46.3168 37.932 46.4035 39.6256C46.4903 41.3193 43.4564 46.7151 41.1794 47.2544C38.9023 47.7938 36.6253 43.4789 37.6012 40.1348C38.577 36.7907 42.9251 33.6322 44.866 35.9278Z'
				fill='#F7C5B4'
			/>
			<path
				d='M39.5732 40.705C39.5732 40.705 41.4621 38.7503 42.8218 37.924C44.1815 37.0977 45.5413 37.1732 45.5413 37.1732L46.0314 38.0664L48.1848 37.9262C48.1848 37.9262 46.0704 35.8982 44.1815 35.5961C42.2927 35.2962 40.8571 37.6996 40.5556 38.2261C40.2542 38.7525 39.5732 40.705 39.5732 40.705Z'
				fill='#263238'
			/>
			<path
				d='M31.6312 50.6741C35.2094 47.0064 39.8545 49.6364 39.8545 49.6364L26.9253 26.4178C29.8117 25.4944 27.6084 26.4437 24.993 26.7328C21.3693 27.1341 18.6173 30.2041 18.819 30.6723C19.771 32.8837 24.5225 49.0021 24.6829 49.8651C25.5092 48.9482 29.8247 52.5252 31.6333 50.672L31.6312 50.6741Z'
				fill='#0E7673'
			/>
			<path
				d='M21.8783 45.2017C21.8783 45.2017 26.7577 36.464 31.2033 32.7963C35.649 29.1286 41.5043 25.4609 41.5043 25.4609L44.8656 26.9712C44.8656 26.9712 47.3595 33.875 47.6848 34.5223C48.0101 35.1695 48.6867 38.6215 48.6867 38.6215C48.6867 38.6215 46.1863 36.0606 44.5598 36.2763C42.9334 36.492 42.5886 36.5719 41.3958 38.19C40.2031 39.8081 36.6249 45.9568 34.3478 47.8985C32.0708 49.8403 27.2998 51.2426 27.2998 51.2426C27.2998 51.2426 22.6373 48.33 21.8783 47.5749L20.5771 46.2805L21.8783 45.2017Z'
				fill='#0E7673'
			/>
			<path
				d='M22.4638 48.0478C23.2901 46.6088 26.5864 41.0231 30.3511 36.7449C34.7057 31.7935 39.0602 29.4203 40.6151 28.0805C41.823 27.0384 43.6555 27.2434 45.1931 27.8712C44.9935 27.3211 44.8656 26.9694 44.8656 26.9694L41.823 25.6016L41.244 25.6231C40.0621 26.3761 35.0938 29.5842 31.2033 32.7945C26.7577 36.4622 21.8783 45.2 21.8783 45.2L20.5771 46.2787L21.8783 47.5732C21.9933 47.6875 22.1993 47.8515 22.4638 48.0478Z'
				fill='#828282'
			/>
			<path
				d='M50.0939 49.3684C50.0939 49.3684 58.4843 47.3663 60.2951 46.7643C62.108 46.1624 65.4629 46.6975 66.3346 47.5648C67.2064 48.4321 67.9459 51.8387 66.6035 53.6402C65.2612 55.4438 61.3685 59.3833 59.2216 61.4524C57.0747 63.5214 53.3165 64.7899 51.3018 65.4588C49.2893 66.1254 44.4555 64.8568 41.3023 64.2571C38.147 63.6551 29.6916 58.6476 27.9459 56.378C26.2001 54.1083 25.9334 54.5744 30.8995 53.1073C35.8656 51.6381 47.8125 49.7697 47.8125 49.7697L50.0939 49.3684Z'
				fill='#0E7673'
			/>
			<path
				d='M45.9698 20.9339C45.9698 20.9339 44.6014 23.0914 44.2002 24.3406C43.8012 25.5898 42.8318 26.895 41.5176 27.8594C40.2056 28.8238 38.6637 29.5616 36.9527 29.2208C35.2417 28.8799 33.9297 25.2467 33.5285 24.3384C33.1295 23.4301 32.6719 21.784 32.6719 20.3644C32.6719 18.9448 33.0145 16.9577 33.8126 16.2199C34.6106 15.482 39.6895 14.8003 42.6562 14.7442C45.6228 14.6881 46.0804 15.8229 46.1932 16.6169C46.3059 17.4108 46.1932 20.25 45.9655 20.9318L45.9698 20.9339Z'
				fill='#F7C5B4'
			/>
			<path
				d='M45.3428 12.0258C45.3428 12.0258 45.2865 16.2264 44.5448 17.4194C43.8031 18.6125 41.8622 19.1799 39.8086 19.236C37.7549 19.2921 33.9316 18.1012 33.9316 18.1012C33.9316 18.1012 33.5326 18.4421 33.1336 18.2155C32.7345 17.989 32.1642 17.592 32.1642 17.592C32.1642 17.592 31.7088 16.5694 31.5938 16.399C31.4789 16.2285 31.8216 13.9006 35.7598 11.9718C39.6958 10.0409 43.0051 10.0992 43.8053 10.3257C44.6034 10.5522 45.345 12.0279 45.345 12.0279L45.3428 12.0258Z'
				fill='#263238'
			/>
			<path
				d='M45.0007 12.1405C45.0007 12.1405 45.2869 16.285 44.773 18.272C44.259 20.2591 45.1156 21.3939 45.5146 21.7348C45.9136 22.0756 47.3406 21.7909 47.6832 20.3152C48.0259 18.8395 48.0259 15.3767 47.2842 13.8449C46.5425 12.3131 45.5732 11.3466 45.2869 11.4048C45.0007 11.4609 45.0007 12.1427 45.0007 12.1427V12.1405Z'
				fill='#263238'
			/>
			<path
				d='M33.6458 19.1196C33.6458 19.1196 33.4181 17.0182 32.5615 16.9621C31.7049 16.906 31.4772 18.8348 31.7049 19.9135C31.9326 20.9923 33.1883 21.0484 33.1883 21.0484'
				fill='#F7C5B4'
			/>
			<path
				d='M32.5113 47.0996C32.5113 47.0996 30.1627 45.2291 28.9548 45.5635C27.7469 45.8979 26.0684 48.3682 26.0684 48.7026C26.0684 49.037 27.2091 49.2376 29.356 49.2376C31.5029 49.2376 34.3221 48.571 34.5238 48.1697C34.7255 47.7684 32.5113 47.1017 32.5113 47.1017V47.0996Z'
				fill='#EDEDED'
			/>
			<path
				d='M47.3426 39.8256C47.3426 39.8256 46.5381 39.0252 44.7923 39.7588C43.0466 40.4923 42.5782 41.6293 42.4437 41.8278C42.3093 42.0284 40.8324 41.9615 40.0951 42.8957C39.3578 43.8299 39.4901 45.1654 40.0279 45.7673C40.5657 46.3692 40.8324 46.7684 40.8324 46.7684C40.8324 46.7684 40.3619 47.2365 41.1686 48.0369C41.9753 48.8374 47.9477 49.038 49.6912 47.1028C51.437 45.1654 51.168 43.5645 50.0946 41.8946C49.0211 40.2248 47.3426 39.8256 47.3426 39.8256Z'
				fill='#FEDEDE'
			/>
			<path
				d='M46.8043 38.2222C46.8043 38.2222 46.3337 38.6904 46.401 39.6246C46.4682 40.5588 47.0711 41.1607 47.0711 41.1607C47.0711 41.1607 45.6615 40.5588 45.6615 41.0938C45.6615 41.6289 45.7959 42.1617 45.7959 42.1617C45.7959 42.1617 44.588 41.1607 44.7225 41.7605L44.8569 42.3624C44.8569 42.3624 43.9179 42.2955 43.8507 42.7637C43.7835 43.2318 43.9851 43.4972 43.9851 43.4972C43.9851 43.4972 44.4557 43.8985 44.254 44.5652C44.0524 45.2318 43.5167 45.4993 43.2478 45.5662C42.9789 45.6331 42.5777 46.6342 42.6428 46.9686C42.7078 47.303 44.9914 48.9038 45.8653 48.9038C46.7393 48.9038 47.6783 49.1045 49.2202 48.5694C50.7642 48.0344 51.9721 45.3634 51.9721 43.7626C51.9721 42.1617 50.8987 40.9579 50.0269 40.3581C49.1551 39.7562 48.6845 39.1564 48.6845 38.6214C48.6845 38.0863 47.9472 37.8209 47.9472 38.4207V39.288C47.9472 39.288 47.1426 38.0194 46.8065 38.2201L46.8043 38.2222Z'
				fill='#263238'
			/>
			<path
				d='M44.1892 44.1637C44.1892 44.1637 44.0547 42.962 43.5842 43.3633C43.1136 43.7646 43.5169 44.2974 42.9791 44.6987C42.4413 45.1 41.5695 45.4323 42.1073 45.8336C42.6451 46.2348 44.187 45.3654 44.187 44.1637H44.1892Z'
				fill='#FEDEDE'
			/>
			<path
				d='M50.0957 47.6332C50.0957 47.6332 52.1081 47.5663 51.9758 49.303C51.8414 51.0398 51.0368 51.0398 48.4193 51.5727C45.8018 52.1077 44.1233 52.1077 41.7075 52.5737C39.2916 53.0419 36.338 53.441 33.9894 53.441C31.6408 53.441 27.7481 51.372 27.5464 50.5695C27.3448 49.769 27.6809 48.8327 29.1577 48.2329C30.6345 47.631 33.1848 48.166 36.004 48.768C38.8232 49.3699 40.634 49.6353 42.5142 49.1693C44.3944 48.7011 48.7555 47.6332 50.0978 47.6332H50.0957Z'
				fill='white'
			/>
			<path
				d='M42.2398 51.3068C42.2398 51.3068 44.7901 50.2388 45.3279 48.569C45.8657 46.8991 44.0527 45.5636 42.912 45.8311C41.7714 46.0987 40.8996 46.6316 40.3618 46.966C39.8239 47.3004 39.1538 47.3004 39.1538 47.3004C39.1538 47.3004 36.4019 46.6337 35.5973 46.6337C34.7928 46.6337 34.1205 46.3662 33.7844 46.2324C33.4482 46.0987 32.7109 45.898 32.1731 46.2993C31.6353 46.7006 31.3685 47.0997 31.568 47.4341C31.7697 47.7685 31.9714 47.501 31.9714 47.501C31.9714 47.501 32.1731 47.8354 32.5092 48.0361C32.8453 48.2367 33.5154 48.1698 33.5154 48.1698C33.5154 48.1698 34.7234 49.3715 36.4691 50.1051C38.2148 50.8386 40.0928 51.4405 40.5634 51.4405C41.034 51.4405 42.2419 51.3068 42.2419 51.3068H42.2398Z'
				fill='#FEDEDE'
			/>
			<path
				d='M43.4514 62.1206C43.6531 62.1206 49.2243 62.1206 51.6401 61.387C54.056 60.6535 56.0034 59.2512 56.4046 59.1843C56.8058 59.1174 58.0158 59.7862 57.6797 60.8542C57.3436 61.9221 56.539 62.2565 56.539 62.2565C56.539 62.2565 56.8079 62.7247 56.8079 63.1907C56.8079 63.6567 56.4046 63.8573 56.4046 63.8573C56.4046 63.8573 56.3373 64.058 56.3373 64.524C56.3373 64.99 55.7323 65.2575 55.7323 65.2575C55.6911 65.7128 55.4092 66.1097 54.9928 66.3039L54.6588 66.4592C54.6588 66.4592 54.3227 66.6599 53.5854 66.9274C52.848 67.1949 50.2978 66.6599 47.5458 66.3924C44.7938 66.1248 43.0481 65.5919 42.6469 65.5251C42.2435 65.4582 43.4514 62.1206 43.4514 62.1206Z'
				fill='#F7C5B4'
			/>
			<path
				d='M56.421 59.8477V61.0904C56.421 61.0904 56.3646 61.4291 55.8528 61.3169C55.3411 61.2047 54.9442 60.6955 55.5688 60.2446'
				fill='#F7C5B4'
			/>
			<path
				d='M56.5377 62.2521C56.5377 62.2521 56.3056 62.1614 56.0216 62.2175C55.7375 62.2736 55.5098 62.2736 55.5098 62.2736'
				fill='#F7C5B4'
			/>
			<path d='M54.6586 66.4588C54.6586 66.4588 54.2617 66.1719 53.6936 66.1719H52.7285' fill='#F7C5B4' />
			<path
				d='M44.0538 61.7891C44.0538 61.7891 38.886 60.3867 34.9933 57.6489C31.1007 54.9111 21.973 50.9716 18.5509 49.5692C15.1288 48.1669 12.3769 47.032 10.9673 48.3006C9.55766 49.5692 9.1543 54.7773 9.1543 56.3134C9.1543 57.8496 11.7718 60.5205 15.4628 62.5226C19.1538 64.5247 26.4034 66.3953 31.1007 66.8634C35.7979 67.3316 39.2893 66.2615 40.9657 66.2615C42.642 66.2615 43.3143 66.2615 43.7849 65.4611C44.2555 64.6607 45.1945 63.1246 44.0538 61.7891Z'
				fill='#0E7673'
			/>
			<path
				d='M37.357 53.3365C37.357 52.9093 36.6067 52.9093 35.8021 52.4843C35.3033 52.2189 33.8742 51.6472 32.8636 51.2502C33.1607 51.3581 33.434 51.4508 33.5533 51.4724C33.8742 51.5264 34.4099 51.4185 34.8392 51.2049C35.2686 50.9913 34.9455 50.8317 34.6788 50.7777C34.4099 50.7238 31.8921 49.0712 31.5711 48.9115C31.2502 48.7519 27.3922 48.7519 27.3922 48.7519C27.3922 48.7519 26.7482 48.8058 26.4272 48.3786C26.1062 47.9514 19.6221 42.4607 19.6221 42.4607L21.6107 39.6215L19.0474 31.2656C17.1759 34.7909 13.4676 42.7865 13.5131 43.7379C13.5673 44.8576 16.1913 46.6699 17.8525 47.7896C19.5137 48.9094 25.9978 52.5878 25.9978 52.5878C25.9978 52.5878 26.8544 53.3882 27.0149 53.5479C27.1754 53.7075 28.8365 55.2005 29.8016 55.6277C30.7666 56.0549 31.6774 56.5878 32.0526 56.6395C32.4277 56.6935 33.5533 56.5856 33.5533 56.5856C33.5533 56.5856 34.1431 56.7453 34.3578 56.7453C34.5725 56.7453 34.8392 56.5317 34.9477 56.1584C35.0539 55.7852 31.7858 54.0786 31.7858 54.0786C31.7858 54.0786 32.7509 54.5058 33.3407 54.8791C33.9306 55.2523 35.5918 55.9988 35.8585 55.9988C36.1252 55.9988 36.663 55.6795 36.4484 55.358C36.2337 55.0387 33.0198 53.3321 32.6967 52.7993C32.6967 52.7993 35.9648 53.919 36.2337 53.919C36.5026 53.919 37.3592 53.7593 37.3592 53.3321L37.357 53.3365Z'
				fill='#F7C5B4'
			/>
			<path
				d='M19.1855 31.7656L20.6515 40.8011L21.6101 39.6253C21.6101 39.6253 19.114 31.9835 19.1877 31.7656H19.1855Z'
				fill='#263238'
			/>
			<path
				d='M21.7704 27.8973C21.7704 27.8973 28.7035 19.358 33.7889 15.0215C38.8743 10.685 42.3072 10.8166 43.6929 10.8835C45.0787 10.9482 47.282 13.8435 47.282 13.8435C47.282 13.8435 45.6729 8.64837 42.1077 8.05723C38.5425 7.46609 32.401 11.6041 30.3538 13.576C28.3066 15.5458 22.8265 22.7733 21.0439 25.7937C19.2613 28.8163 16.5527 36.3049 16.5527 36.3049C16.5527 36.3049 19.9856 29.7354 21.7682 27.8973H21.7704Z'
				fill='#0E7673'
			/>
		</svg>
	);
};

export default v1_breastfeeding;
