const v1_empty_cart: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='216' height='123' viewBox='0 0 216 123' {...props} fill='none'>
			<path
				opacity='0.1'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M46 97H137C137.515 97 138.017 96.9444 138.5 96.8389C138.983 96.9444 139.485 97 140 97H192C195.866 97 199 93.866 199 90C199 86.134 195.866 83 192 83H186C182.134 83 179 79.866 179 76C179 72.134 182.134 69 186 69H205C208.866 69 212 65.866 212 62C212 58.134 208.866 55 205 55H183C186.866 55 190 51.866 190 48C190 44.134 186.866 41 183 41H119C122.866 41 126 37.866 126 34C126 30.134 122.866 27 119 27H62C58.134 27 55 30.134 55 34C55 37.866 58.134 41 62 41H22C18.134 41 15 44.134 15 48C15 51.866 18.134 55 22 55H47C50.866 55 54 58.134 54 62C54 65.866 50.866 69 47 69H7C3.13401 69 0 72.134 0 76C0 79.866 3.13401 83 7 83H46C42.134 83 39 86.134 39 90C39 93.866 42.134 97 46 97ZM209 97C212.866 97 216 93.866 216 90C216 86.134 212.866 83 209 83C205.134 83 202 86.134 202 90C202 93.866 205.134 97 209 97Z'
				fill='#FE964A'
			/>
			<path
				d='M150.001 111H172.429M47.001 111H68.4551H47.001ZM34.1289 111H41.001H34.1289ZM177.129 111H180.001H177.129Z'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M81 6.30273L92.0996 18.7559M133.1 6.30273L122 18.7559L133.1 6.30273ZM107 2V18.7559V2Z'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M75.7985 34H138.405L132.799 42.4098L140.274 48.0164H73.9297L82.3395 42.4098L75.7985 34Z'
				fill='#FFF4ED'
			/>
			<rect x='72' y='46' width='71' height='75' rx='2' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M76.5859 87.0061V52.6453C76.5859 51.2297 77.7462 50.082 79.1774 50.082L140.154 114.164C140.154 116.052 138.643 117.582 136.779 117.582H79.9609C78.0969 117.582 76.5859 116.052 76.5859 114.164V101.395V97.9301V87.0061ZM76.5859 94.4248V90.5765V94.4248Z'
				fill='#FFF4ED'
			/>
			<path
				d='M73 87.4631V49.8853C73 48.3371 74.2705 47.082 75.8377 47.082H140.761C141.782 47.082 142.609 47.9187 142.609 48.9509V117.164C142.609 119.228 140.954 120.902 138.913 120.902H76.6956C74.6546 120.902 73 119.228 73 117.164V103.199V99.4099M73 95.5764V91.3678'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<path
				d='M74.8636 47.082V35.8689C74.8636 34.8368 75.6145 34.0001 76.5408 34.0001L138.596 34.0001C139.523 34.0001 140.273 34.8368 140.273 35.8689V47.082'
				stroke='#FE964A'
				strokeWidth='2.5'
			/>
			<path
				d='M92.1494 66.705C93.9556 66.705 95.4199 65.2408 95.4199 63.4346C95.4199 61.6283 93.9556 60.1641 92.1494 60.1641C90.3432 60.1641 88.8789 61.6283 88.8789 63.4346C88.8789 65.2408 90.3432 66.705 92.1494 66.705Z'
				fill='#E8F0FE'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<path
				d='M122.985 66.705C124.792 66.705 126.256 65.2408 126.256 63.4346C126.256 61.6283 124.792 60.1641 122.985 60.1641C121.179 60.1641 119.715 61.6283 119.715 63.4346C119.715 65.2408 121.179 66.705 122.985 66.705Z'
				fill='#E8F0FE'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<path
				d='M122.519 66.7045C122.519 74.9616 115.825 81.6553 107.568 81.6553C99.3109 81.6553 92.6172 74.9616 92.6172 66.7045'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<path
				d='M75.8547 34.9316L82.4331 41.4253C82.8262 41.8133 82.8303 42.4464 82.4423 42.8395C82.3752 42.9075 82.2986 42.9656 82.215 43.0118L74.8633 47.0821'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<path
				d='M139.44 34.999L133.145 41.4195C132.758 41.8138 132.764 42.447 133.159 42.8336C133.225 42.8982 133.299 42.9533 133.38 42.9975L140.867 47.0818'
				stroke='#FE964A'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
		</svg>
	);
};
export default v1_empty_cart;
