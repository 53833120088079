export const v1_chevron_right: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
		<g>
			<path
				d='M7.5 15L12.5 10L7.5 5'
				stroke='white'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
	</svg>
);

export default v1_chevron_right;
