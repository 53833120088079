const v1_taka: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
			<circle opacity='0.1' cx='12' cy='12' r='12' fill='#FE964A' />
			<circle cx='12' cy='12' r='10' fill='#FE964A' />
			<path
				d='M16.2609 10.2691V11.7249H11.8262V14.7425C11.8262 14.9896 11.8623 15.1748 11.9344 15.2984C12.0065 15.4131 12.1687 15.4704 12.4211 15.4704C12.6284 15.4704 12.8312 15.4395 13.0295 15.3778C13.2278 15.3072 13.4081 15.219 13.5703 15.1131C13.7326 14.9984 13.8633 14.866 13.9624 14.716C14.0616 14.566 14.1112 14.4116 14.1112 14.2528C14.1112 14.1734 14.0751 14.0984 14.003 14.0278C13.9309 13.9572 13.7912 13.9219 13.5839 13.9219C13.4216 13.9219 13.2774 13.9352 13.1512 13.9616L12.9349 12.6117C13.0971 12.5675 13.2774 12.5367 13.4757 12.519C13.683 12.5014 13.8948 12.4925 14.1112 12.4925C14.6429 12.4925 15.0711 12.6117 15.3956 12.8499C15.7201 13.0881 15.8823 13.4587 15.8823 13.9616C15.8823 14.4381 15.7696 14.8616 15.5443 15.2322C15.319 15.6028 15.0215 15.9116 14.652 16.1586C14.2824 16.4057 13.8768 16.5954 13.4351 16.7278C13.0025 16.8513 12.5789 16.913 12.1642 16.913C11.7857 16.913 11.4657 16.8689 11.2043 16.7807C10.9519 16.6925 10.7491 16.5778 10.5959 16.4366C10.4967 16.3395 10.4021 16.2292 10.312 16.1057C10.2308 15.9822 10.1632 15.8101 10.1092 15.5895C10.0641 15.369 10.0416 15.0734 10.0416 14.7028V11.7249H8.90586V10.2691H16.2609ZM9.90635 7C10.2038 7 10.4652 7.03529 10.6905 7.10588C10.9159 7.17647 11.1051 7.28676 11.2584 7.43676C11.4386 7.6044 11.5784 7.83822 11.6775 8.13821C11.7767 8.43821 11.8262 8.86173 11.8262 9.40878V10.322H10.0416V9.47495C10.0416 9.25437 10.0235 9.06908 9.98748 8.91908C9.95142 8.76908 9.87931 8.65879 9.77115 8.5882C9.66299 8.50879 9.50075 8.46909 9.28442 8.46909C9.16725 8.46909 9.04106 8.48232 8.90586 8.50879C8.77967 8.53526 8.64897 8.57497 8.51377 8.62791L8 7.35735C8.35153 7.23382 8.667 7.14558 8.94642 7.09264C9.22584 7.03088 9.54581 7 9.90635 7Z'
				fill='white'
			/>
		</svg>
	);
};

export default v1_taka;
