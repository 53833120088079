const v1_generic: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<path
				d='M5.68277 16.2067C3.98126 13.338 4.00938 10.5045 5.77769 7.66751C5.33473 7.24565 4.87771 6.80972 4.4207 6.37731C4.1043 6.0785 3.78791 5.78319 3.47503 5.48086C2.87739 4.9008 2.83872 4.04301 3.38714 3.45592C3.93556 2.86532 4.82147 2.84422 5.42965 3.42077C6.22064 4.16957 7.00109 4.92892 7.80262 5.69882C9.0893 4.85861 10.499 4.4262 12.0388 4.43324C13.5786 4.44027 14.9672 4.9008 16.2258 5.74101C16.9922 4.98166 17.748 4.23285 18.5038 3.48405C18.9187 3.06922 19.4073 2.89344 19.9804 3.0657C21.0245 3.37507 21.3444 4.64768 20.5745 5.43516C19.8151 6.21209 19.0417 6.98198 18.2542 7.76946C19.098 9.04207 19.562 10.4553 19.562 12.0021C19.562 13.5454 19.105 14.9517 18.2437 16.2489C19.0136 17.0153 19.7765 17.7746 20.5393 18.5375C20.8979 18.8961 21.0561 19.3285 20.9542 19.8347C20.8452 20.3796 20.5182 20.7487 19.9944 20.9175C19.4565 21.0897 18.9679 20.9702 18.5671 20.5765C17.8359 19.8593 17.1187 19.1316 16.3945 18.4074C16.2996 18.309 16.2328 18.2668 16.0852 18.3547C13.2974 20.0281 10.5236 19.9894 7.76747 18.2703C7.74637 18.2562 7.72528 18.2492 7.67606 18.2211C7.39482 18.5164 7.10655 18.8152 6.82179 19.1175C6.35775 19.6027 5.90424 20.0984 5.43317 20.5765C5.02888 20.9843 4.52616 21.0968 3.98477 20.9175C3.45042 20.7417 3.1305 20.355 3.03207 19.7995C2.94418 19.2898 3.12699 18.8714 3.48206 18.5094C4.14297 17.8273 4.80037 17.1418 5.45777 16.4563C5.53863 16.3649 5.61597 16.2805 5.68277 16.2067ZM11.9509 16.7235C14.5454 16.7797 16.6582 14.7372 16.725 12.1041C16.7918 9.48854 14.7528 7.33353 12.1583 7.27729C9.49358 7.21752 7.33506 9.22136 7.27529 11.8088C7.21202 14.5614 9.21234 16.6637 11.9509 16.7235Z'
				fill='#0E7673'
			/>
		</svg>
	);
};
export default v1_generic;
