const v1_notified: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
			<g clipPath='url(#clip0_393_1685)'>
				<path
					d='M8.33398 20C6.61157 20 5.20898 18.5983 5.20898 16.875C5.20898 16.53 5.48898 16.25 5.83398 16.25C6.17899 16.25 6.45898 16.53 6.45898 16.875C6.45898 17.9092 7.30066 18.75 8.33398 18.75C9.36731 18.75 10.209 17.9092 10.209 16.875C10.209 16.53 10.489 16.25 10.834 16.25C11.179 16.25 11.459 16.53 11.459 16.875C11.459 18.5983 10.0565 20 8.33398 20Z'
					fill='#323B49'
				/>
				<path
					d='M15.2083 17.5001H1.45828C0.654144 17.5001 0 16.8459 0 16.0418C0 15.615 0.185852 15.211 0.509949 14.9334C0.530853 14.9151 0.553284 14.8985 0.57663 14.8834C1.79993 13.8158 2.5 12.28 2.5 10.6583V8.33336C2.5 5.61928 4.33914 3.28927 6.9725 2.66671C7.30911 2.58919 7.64587 2.79503 7.72507 3.1318C7.80411 3.46764 7.59582 3.80425 7.26089 3.88344C5.19333 4.37172 3.75 6.20171 3.75 8.33336V10.6583C3.75 12.6717 2.86743 14.5768 1.33087 15.8842C1.31836 15.8942 1.30753 15.9034 1.2941 15.9126C1.27167 15.9409 1.25 15.9843 1.25 16.0418C1.25 16.155 1.34506 16.2501 1.45828 16.2501H15.2083C15.3217 16.2501 15.4167 16.155 15.4167 16.0418C15.4167 15.9834 15.395 15.9409 15.3717 15.9126C15.3592 15.9034 15.3474 15.8942 15.3358 15.8842C14.5892 15.2476 13.9999 14.4793 13.5851 13.6001C13.4367 13.2884 13.5699 12.9159 13.8824 12.7676C14.1975 12.6193 14.5666 12.7543 14.7142 13.0659C15.0441 13.7626 15.5066 14.3743 16.0916 14.8859C16.1133 14.9009 16.1359 14.9168 16.1549 14.9334C16.4809 15.211 16.6667 15.615 16.6667 16.0418C16.6667 16.8459 16.0126 17.5001 15.2083 17.5001Z'
					fill='#323B49'
				/>
				<path
					d='M13.8243 7.90833L17.6431 4.08958L16.8848 3.33125L13.8243 6.39167L12.2806 4.84792L11.5223 5.60625L13.8243 7.90833ZM14.5827 10.8333C13.8334 10.8333 13.1292 10.6911 12.4702 10.4068C11.8112 10.1224 11.2379 9.73646 10.7504 9.24896C10.2629 8.76146 9.87695 8.18819 9.59258 7.52917C9.3082 6.87014 9.16602 6.16597 9.16602 5.41667C9.16602 4.66736 9.3082 3.96319 9.59258 3.30417C9.87695 2.64514 10.2629 2.07187 10.7504 1.58437C11.2379 1.09687 11.8112 0.710937 12.4702 0.426562C13.1292 0.142187 13.8334 0 14.5827 0C15.332 0 16.0362 0.142187 16.6952 0.426562C17.3542 0.710937 17.9275 1.09687 18.415 1.58437C18.9025 2.07187 19.2884 2.64514 19.5728 3.30417C19.8572 3.96319 19.9993 4.66736 19.9993 5.41667C19.9993 6.16597 19.8572 6.87014 19.5728 7.52917C19.2884 8.18819 18.9025 8.76146 18.415 9.24896C17.9275 9.73646 17.3542 10.1224 16.6952 10.4068C16.0362 10.6911 15.332 10.8333 14.5827 10.8333Z'
					fill='#0E7673'
				/>
			</g>
			<defs>
				<clipPath id='clip0_393_1685'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
export default v1_notified;
