const v1_cart: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0_1_30439' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
				<rect width='32' height='32' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_1_30439)'>
				<path
					d='M9.3335 29.3334C8.60016 29.3334 7.97239 29.0723 7.45016 28.5501C6.92794 28.0279 6.66683 27.4001 6.66683 26.6667C6.66683 25.9334 6.92794 25.3056 7.45016 24.7834C7.97239 24.2612 8.60016 24.0001 9.3335 24.0001C10.0668 24.0001 10.6946 24.2612 11.2168 24.7834C11.7391 25.3056 12.0002 25.9334 12.0002 26.6667C12.0002 27.4001 11.7391 28.0279 11.2168 28.5501C10.6946 29.0723 10.0668 29.3334 9.3335 29.3334ZM22.6668 29.3334C21.9335 29.3334 21.3057 29.0723 20.7835 28.5501C20.2613 28.0279 20.0002 27.4001 20.0002 26.6667C20.0002 25.9334 20.2613 25.3056 20.7835 24.7834C21.3057 24.2612 21.9335 24.0001 22.6668 24.0001C23.4002 24.0001 24.0279 24.2612 24.5502 24.7834C25.0724 25.3056 25.3335 25.9334 25.3335 26.6667C25.3335 27.4001 25.0724 28.0279 24.5502 28.5501C24.0279 29.0723 23.4002 29.3334 22.6668 29.3334ZM8.20016 8.00008L11.4002 14.6667H20.7335L24.4002 8.00008H8.20016ZM9.3335 22.6667C8.3335 22.6667 7.57794 22.2279 7.06683 21.3501C6.55572 20.4723 6.5335 19.6001 7.00016 18.7334L8.80016 15.4667L4.00016 5.33341H2.6335C2.25572 5.33341 1.94461 5.20564 1.70016 4.95008C1.45572 4.69453 1.3335 4.37786 1.3335 4.00008C1.3335 3.6223 1.46127 3.30564 1.71683 3.05008C1.97238 2.79453 2.28905 2.66675 2.66683 2.66675H4.8335C5.07794 2.66675 5.31127 2.73341 5.5335 2.86675C5.75572 3.00008 5.92239 3.18897 6.0335 3.43341L6.9335 5.33341H26.6002C27.2002 5.33341 27.6113 5.55564 27.8335 6.00008C28.0557 6.44453 28.0446 6.91119 27.8002 7.40008L23.0668 15.9334C22.8224 16.3779 22.5002 16.7223 22.1002 16.9667C21.7002 17.2112 21.2446 17.3334 20.7335 17.3334H10.8002L9.3335 20.0001H24.0335C24.4113 20.0001 24.7224 20.1279 24.9668 20.3834C25.2113 20.639 25.3335 20.9556 25.3335 21.3334C25.3335 21.7112 25.2057 22.0279 24.9502 22.2834C24.6946 22.539 24.3779 22.6667 24.0002 22.6667H9.3335Z'
					fill='#718096'
				/>
			</g>
		</svg>
	);
};
export default v1_cart;
