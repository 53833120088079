const v1_alcohol: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='76' height='75' viewBox='0 0 76 75' fill='none'>
			<rect opacity='0.1' x='0.109375' width='75.3876' height='75' rx='37.5' fill='#FD6A6A' />
			<rect x='13.1699' y='14' width='50.2687' height='50' rx='25' stroke='#FD4F4F' strokeWidth='2' />
			<path
				d='M56.0449 32.2305C55.9807 33.4407 55.8524 34.4598 55.5957 35.4152C53.7351 42.3579 44.1749 43.6954 40.5177 37.5171C39.5553 35.9247 39.1703 34.0776 39.042 32.2305H56.0449Z'
				fill='#F70015'
			/>
			<path
				d='M53.8004 20.4453L41.4813 20.5727C41.4813 20.5727 36.605 31.0186 40.4547 37.5154C44.112 43.6937 53.6721 42.2924 55.5328 35.4135C56.431 32.1651 56.3027 27.388 53.8004 20.4453Z'
				stroke='black'
				strokeWidth='1.204'
				strokeMiterlimit='10'
			/>
			<path d='M47.3848 41.4648V59.6177' stroke='black' strokeWidth='1.204' strokeMiterlimit='10' />
			<path d='M52.5177 59.6172H41.9951' stroke='black' strokeWidth='1.204' strokeMiterlimit='10' />
			<path
				d='M31.8571 23.4395V10H27.6866V23.4395C21.9121 23.949 22.1687 33.3121 22.1687 33.3121V60H37.3109L37.3751 33.3121C37.3109 33.3121 37.0543 23.8854 31.8571 23.4395Z'
				fill='#5A64DF'
			/>
			<path d='M26.0811 39.2366L34.8712 30.5742' stroke='black' strokeWidth='1.204' strokeMiterlimit='10' />
			<path d='M31.1494 39.6194L35.3199 35.543' stroke='black' strokeWidth='1.204' strokeMiterlimit='10' />
			<path d='M55.3918 21L20.2109 56' stroke='#FD4F4F' strokeWidth='2' />
		</svg>
	);
};

export default v1_alcohol;
