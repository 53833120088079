const v1_rx: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' {...props} fill='none'>
		<mask
			id='mask0_344_11950'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='0'
			width='32'
			height='32'>
			<rect x='0.25' y='0.25' width='31.5' height='31.5' fill='#30363C' stroke='#A0AEC0' strokeWidth='0.5' />
		</mask>
		<g mask='url(#mask0_344_11950)'>
			<circle cx='16' cy='16.0012' r='11.75' stroke='#718096' strokeWidth='0.5' />
			<path
				d='M22.4649 21.0096C22.7331 21.2436 22.7331 21.6337 22.4649 21.8937L21.875 22.4918C21.6068 22.7258 21.2046 22.7258 20.9632 22.4918L18.3889 19.9694L15.7878 22.4918C15.5465 22.7258 15.1442 22.7258 14.9029 22.4918L14.2861 21.8937C14.0448 21.6337 14.0448 21.2436 14.2861 21.0096L16.8604 18.5132L13.8571 15.6008H12.7308V17.057C12.7308 17.3951 12.4627 17.6811 12.1141 17.6811H11.2828C10.9342 17.6811 10.666 17.3951 10.666 17.057V10.1921C10.666 9.72404 11.0414 9.33398 11.4973 9.33398H15.0638C16.5923 9.33398 17.9867 10.3481 18.3085 11.8043C18.6303 13.3125 17.8258 14.6907 16.5655 15.2628L18.3889 17.031L20.9364 14.5347C21.1777 14.3007 21.58 14.3007 21.8481 14.5347L22.4381 15.1328C22.7062 15.3668 22.7062 15.7569 22.4381 16.0169L19.8638 18.5132L22.4649 21.0096ZM12.7845 11.4403V13.5206H15.1174C15.6805 13.5206 16.1632 13.0525 16.1632 12.4804C16.1632 11.9083 15.7074 11.4403 15.1174 11.4403H12.7845Z'
				fill='#718096'
			/>
		</g>
	</svg>
);

export default v1_rx;
