const v1_notified_block: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<g clipPath='url(#clip0_990_25503)'>
				<path
					d='M9.99823 24C11.8093 24 13.3243 22.7089 13.6722 21H6.32422C6.6723 22.7089 8.18732 24 9.99823 24Z'
					fill='#718096'
				/>
				<path
					d='M17.5759 14.4959C17.5499 14.4959 17.5259 14.4999 17.4999 14.4999C13.0889 14.4999 9.50006 10.9111 9.50006 6.50006C9.50006 4.76697 10.06 3.16608 11.0001 1.85394V0.999939C11.0001 0.44696 10.552 0 9.99994 0C9.44806 0 9 0.44696 9 0.999939V2.08008C5.61292 2.56805 3 5.48108 3 9V11.788C3 13.767 2.133 15.635 0.611939 16.921C0.223022 17.254 0 17.738 0 18.2499C0 19.2151 0.784973 20.0001 1.74994 20.0001H18.2499C19.2151 20.0001 20.0001 19.2151 20.0001 18.2499C20.0001 17.738 19.777 17.254 19.3781 16.9131C18.59 16.246 17.9821 15.416 17.5759 14.4959Z'
					fill='#718096'
				/>
				<path
					d='M16.59 9.49L21.1725 4.9075L20.2625 3.9975L16.59 7.67L14.7375 5.8175L13.8275 6.7275L16.59 9.49ZM17.5 13C16.6008 13 15.7558 12.8294 14.965 12.4881C14.1742 12.1469 13.4862 11.6837 12.9012 11.0987C12.3162 10.5138 11.8531 9.82583 11.5119 9.035C11.1706 8.24417 11 7.39917 11 6.5C11 5.60083 11.1706 4.75583 11.5119 3.965C11.8531 3.17417 12.3162 2.48625 12.9012 1.90125C13.4862 1.31625 14.1742 0.853125 14.965 0.511875C15.7558 0.170625 16.6008 0 17.5 0C18.3992 0 19.2442 0.170625 20.035 0.511875C20.8258 0.853125 21.5138 1.31625 22.0987 1.90125C22.6837 2.48625 23.1469 3.17417 23.4881 3.965C23.8294 4.75583 24 5.60083 24 6.5C24 7.39917 23.8294 8.24417 23.4881 9.035C23.1469 9.82583 22.6837 10.5138 22.0987 11.0987C21.5138 11.6837 20.8258 12.1469 20.035 12.4881C19.2442 12.8294 18.3992 13 17.5 13Z'
					fill='#0E7673'
				/>
			</g>
			<defs>
				<clipPath id='clip0_990_25503'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
export default v1_notified_block;
