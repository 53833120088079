const v1_offer: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg
			width='41'
			height='41'
			viewBox='0 0 41 41'
			{...props}
			// fill={props?.fill ? props.fill : "#718096"}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.48606 26.5327C6.92248 25.4742 6.27901 24.7793 5.33918 23.9118C2.50548 21.301 2.87085 17.1055 5.99483 14.7772C6.49012 14.4074 6.618 14.0742 6.54898 13.4444C6.05166 8.94005 9.26293 5.89653 13.7429 6.51417C14.0534 6.55684 14.5284 6.25208 14.7497 5.96764C16.3857 3.87497 18.4623 3.01555 21.0788 3.59053C22.5322 3.90951 23.6507 4.7608 24.4992 5.95342C24.8524 6.44916 25.1528 6.61373 25.8186 6.49996C29.9737 5.79292 33.4529 9.32202 32.7384 13.4891C32.6349 14.0884 32.8115 14.4034 33.2723 14.7467C36.7312 17.3229 36.7454 21.9268 33.2966 24.4807C32.7932 24.8545 32.6389 25.1938 32.7506 25.8277C33.4773 29.9419 29.9128 33.467 25.7536 32.7396C25.1893 32.6401 24.9051 32.7518 24.5763 33.1825C21.8583 36.7604 17.4453 36.7726 14.7314 33.213C14.4147 32.7965 14.163 32.6259 13.5744 32.7315C9.75618 33.4203 6.49418 30.6429 6.48606 26.5327ZM15.8965 25.2202C16.1381 25.0597 16.6334 24.8322 16.9988 24.4705C19.5219 21.9837 22.0187 19.4684 24.5195 16.9572C24.7082 16.7683 24.8828 16.5549 25.0147 16.3233C25.3943 15.6549 25.307 14.9946 24.7732 14.4724C24.2393 13.9503 23.5817 13.8751 22.9199 14.2713C22.6905 14.4074 22.4794 14.5842 22.2907 14.7731C19.7817 17.2762 17.2769 19.7813 14.7781 22.2946C14.567 22.5079 14.3681 22.7517 14.232 23.0158C13.7165 24.0317 14.4919 25.2487 15.8965 25.2202ZM15.5576 17.6114C16.7146 17.6277 17.6341 16.748 17.6585 15.6C17.6828 14.4623 16.7816 13.5358 15.6306 13.5155C14.4411 13.4952 13.5825 14.3322 13.5683 15.5249C13.5561 16.7317 14.3782 17.5952 15.5576 17.6114ZM23.6933 25.7383C24.8463 25.7464 25.7638 24.8566 25.78 23.7127C25.7942 22.5729 24.8909 21.6566 23.738 21.6444C22.5484 21.6322 21.7 22.4713 21.6918 23.67C21.6797 24.8728 22.5099 25.7282 23.6933 25.7383Z'
				fill='currentColor'
			/>
		</svg>
	);
};
export default v1_offer;
