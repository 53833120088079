export const v1_delete: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' {...props} fill='none'>
		<mask
			id='mask0_3558_12770'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='0'
			width='20'
			height='20'>
			<rect width='20' height='20' fill='#D9D9D9' />
		</mask>
		<g mask='url(#mask0_3558_12770)'>
			<path
				d='M5.89648 17.5C5.41037 17.5 4.99718 17.3299 4.6569 16.9896C4.31662 16.6493 4.14648 16.2361 4.14648 15.75V5.125C3.91037 5.125 3.70551 5.03819 3.5319 4.86458C3.35829 4.69097 3.27148 4.48611 3.27148 4.25C3.27148 4.01389 3.35829 3.80903 3.5319 3.63542C3.70551 3.46181 3.91037 3.375 4.14648 3.375H7.52148C7.52148 3.13889 7.60829 2.93403 7.7819 2.76042C7.95551 2.58681 8.16037 2.5 8.39648 2.5H11.584C11.8201 2.5 12.025 2.58681 12.1986 2.76042C12.3722 2.93403 12.459 3.13889 12.459 3.375H15.834C16.0701 3.375 16.275 3.46181 16.4486 3.63542C16.6222 3.80903 16.709 4.01389 16.709 4.25C16.709 4.48611 16.6222 4.69097 16.4486 4.86458C16.275 5.03819 16.0701 5.125 15.834 5.125V15.75C15.834 16.2361 15.6638 16.6493 15.3236 16.9896C14.9833 17.3299 14.5701 17.5 14.084 17.5H5.89648ZM7.43815 13.125C7.43815 13.3611 7.52496 13.566 7.69857 13.7396C7.87218 13.9132 8.07704 14 8.31315 14C8.54926 14 8.75412 13.9132 8.92773 13.7396C9.10135 13.566 9.18815 13.3611 9.18815 13.125V7.75C9.18815 7.51389 9.10135 7.30903 8.92773 7.13542C8.75412 6.96181 8.54926 6.875 8.31315 6.875C8.07704 6.875 7.87218 6.96181 7.69857 7.13542C7.52496 7.30903 7.43815 7.51389 7.43815 7.75V13.125ZM10.7715 13.125C10.7715 13.3611 10.8583 13.566 11.0319 13.7396C11.2055 13.9132 11.4104 14 11.6465 14C11.8826 14 12.0875 13.9132 12.2611 13.7396C12.4347 13.566 12.5215 13.3611 12.5215 13.125V7.75C12.5215 7.51389 12.4347 7.30903 12.2611 7.13542C12.0875 6.96181 11.8826 6.875 11.6465 6.875C11.4104 6.875 11.2055 6.96181 11.0319 7.13542C10.8583 7.30903 10.7715 7.51389 10.7715 7.75V13.125Z'
				fill='#A0AEC0'
			/>
		</g>
	</svg>
);

export default v1_delete;
