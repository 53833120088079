export const v1_lab: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
		<mask
			id='mask0_1246_18965'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='0'
			width='24'
			height='24'>
			<rect width='24' height='24' fill='#D9D9D9' />
		</mask>
		<g mask='url(#mask0_1246_18965)'>
			<path
				d='M5.00018 21C4.15018 21 3.54602 20.6208 3.18768 19.8625C2.82935 19.1042 2.91685 18.4 3.45018 17.75L9.00018 11V5H8.00018C7.71685 5 7.47935 4.90417 7.28768 4.7125C7.09602 4.52083 7.00018 4.28333 7.00018 4C7.00018 3.71667 7.09602 3.47917 7.28768 3.2875C7.47935 3.09583 7.71685 3 8.00018 3H16.0002C16.2835 3 16.521 3.09583 16.7127 3.2875C16.9044 3.47917 17.0002 3.71667 17.0002 4C17.0002 4.28333 16.9044 4.52083 16.7127 4.7125C16.521 4.90417 16.2835 5 16.0002 5H15.0002V11L20.5502 17.75C21.0835 18.4 21.171 19.1042 20.8127 19.8625C20.4544 20.6208 19.8502 21 19.0002 21H5.00018Z'
				fill='#718096'
			/>
		</g>
	</svg>
);

export default v1_lab;
