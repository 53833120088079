const v1_share: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props} fill='none'>
		<circle cx='12' cy='12' r='11.75' fill='white' stroke='#FB964A' strokeWidth='0.5' />
		<mask
			id='mask0_344_11975'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='3'
			y='4'
			width='17'
			height='16'>
			<rect x='3.99805' y='4' width='16' height='16' fill='#D9D9D9' />
		</mask>
		<g mask='url(#mask0_344_11975)'>
			<path
				d='M15.998 18.6668C15.4425 18.6668 14.9703 18.4724 14.5814 18.0835C14.1925 17.6946 13.998 17.2224 13.998 16.6668C13.998 16.5891 14.0036 16.5085 14.0147 16.4252C14.0258 16.3418 14.0425 16.2668 14.0647 16.2002L9.36471 13.4668C9.17582 13.6335 8.96471 13.7641 8.73138 13.8585C8.49805 13.9529 8.2536 14.0002 7.99805 14.0002C7.44249 14.0002 6.97027 13.8057 6.58138 13.4168C6.19249 13.0279 5.99805 12.5557 5.99805 12.0002C5.99805 11.4446 6.19249 10.9724 6.58138 10.5835C6.97027 10.1946 7.44249 10.0002 7.99805 10.0002C8.2536 10.0002 8.49805 10.0474 8.73138 10.1418C8.96471 10.2363 9.17582 10.3668 9.36471 10.5335L14.0647 7.80016C14.0425 7.7335 14.0258 7.6585 14.0147 7.57516C14.0036 7.49183 13.998 7.41127 13.998 7.3335C13.998 6.77794 14.1925 6.30572 14.5814 5.91683C14.9703 5.52794 15.4425 5.3335 15.998 5.3335C16.5536 5.3335 17.0258 5.52794 17.4147 5.91683C17.8036 6.30572 17.998 6.77794 17.998 7.3335C17.998 7.88905 17.8036 8.36127 17.4147 8.75016C17.0258 9.13905 16.5536 9.3335 15.998 9.3335C15.7425 9.3335 15.498 9.28627 15.2647 9.19183C15.0314 9.09738 14.8203 8.96683 14.6314 8.80016L9.93138 11.5335C9.9536 11.6002 9.97027 11.6752 9.98138 11.7585C9.99249 11.8418 9.99805 11.9224 9.99805 12.0002C9.99805 12.0779 9.99249 12.1585 9.98138 12.2418C9.97027 12.3252 9.9536 12.4002 9.93138 12.4668L14.6314 15.2002C14.8203 15.0335 15.0314 14.9029 15.2647 14.8085C15.498 14.7141 15.7425 14.6668 15.998 14.6668C16.5536 14.6668 17.0258 14.8613 17.4147 15.2502C17.8036 15.6391 17.998 16.1113 17.998 16.6668C17.998 17.2224 17.8036 17.6946 17.4147 18.0835C17.0258 18.4724 16.5536 18.6668 15.998 18.6668Z'
				fill='#FB964A'
			/>
		</g>
	</svg>
);

export default v1_share;
