const v1_adminMeds: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' {...props} xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0_1250_49682' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
				<rect width='24' height='24' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_1250_49682)'>
				<path d='M8.2 15.775C8.68333 16.2583 9.25417 16.5042 9.9125 16.5125C10.5708 16.5208 11.1417 16.2833 11.625 15.8L13.025 14.4L9.6 10.975L8.2 12.375C7.71667 12.8583 7.475 13.425 7.475 14.075C7.475 14.725 7.71667 15.2917 8.2 15.775ZM15.8 8.225C15.3167 7.75833 14.7458 7.51667 14.0875 7.5C13.4292 7.48333 12.8583 7.71667 12.375 8.2L11 9.575L14.425 13L15.8 11.625C16.2833 11.1417 16.5208 10.575 16.5125 9.925C16.5042 9.275 16.2667 8.70833 15.8 8.225ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.2C9.41667 2.4 9.77917 1.91667 10.2875 1.55C10.7958 1.18333 11.3667 1 12 1C12.6333 1 13.2042 1.18333 13.7125 1.55C14.2208 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM12 4.25C12.2167 4.25 12.3958 4.17917 12.5375 4.0375C12.6792 3.89583 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6792 3.10417 12.5375 2.9625C12.3958 2.82083 12.2167 2.75 12 2.75C11.7833 2.75 11.6042 2.82083 11.4625 2.9625C11.3208 3.10417 11.25 3.28333 11.25 3.5C11.25 3.71667 11.3208 3.89583 11.4625 4.0375C11.6042 4.17917 11.7833 4.25 12 4.25Z' />
			</g>
		</svg>
	);
};
export default v1_adminMeds;
