import { IC_CHATICON, IC_LIVECHAT, IC_MESSENGER, IC_WHATSAPP } from '@/assets/icons';
import { useEffect, useState } from 'react';
import styles from './ChatWidget.module.scss';

const ChatWidget = () => {
	const [widgetVisible, setWidgetVisible] = useState(false);
	const [showChatOptions, setShowChatOptions] = useState(true);

	useEffect(() => {
		const handleWidgetLoaded = () => {
			setShowChatOptions(false);
		};

		const handleWidgetClosed = () => {
			setShowChatOptions(true);
			window.HubSpotConversations.widget.remove();
		};

		const loadHubSpotScript = () => {
			return new Promise((resolve, reject) => {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.id = 'hs-script-loader';
				script.async = true;
				script.defer = true;
				script.src = '//js-na1.hs-scripts.com/46092745.js';
				script.onload = resolve;
				script.onerror = reject;
				document.head.appendChild(script);
				window.hsConversationsSettings = {
					loadImmediately: false
				};
			});
		};

		const waitForHubSpotConversations = () => {
			return new Promise((resolve) => {
				const interval = setInterval(() => {
					if (window.HubSpotConversations) {
						clearInterval(interval);
						resolve(window.HubSpotConversations);
					}
				}, 100);
			});
		};

		const setupHubSpotListeners = () => {
			if (window.HubSpotConversations) {
				window.HubSpotConversations.on('widgetLoaded', handleWidgetLoaded);
				window.HubSpotConversations.on('widgetClosed', handleWidgetClosed);
			}
		};

		loadHubSpotScript().then(waitForHubSpotConversations).then(setupHubSpotListeners);

		return () => {
			if (window.HubSpotConversations) {
				window.HubSpotConversations.off('widgetLoaded', handleWidgetLoaded);
				window.HubSpotConversations.off('widgetClosed', handleWidgetClosed);
			}
		};
	}, []);

	const handleLiveChatClick = () => {
		window.HubSpotConversations.widget.load();
		window.HubSpotConversations.widget.open();
		setWidgetVisible(false);
	};

	return (
		<div>
			{showChatOptions && (
				<div id='chatIcon' className={styles.chatIcon} onClick={() => setWidgetVisible(!widgetVisible)}>
					<IC_CHATICON />
				</div>
			)}

			{widgetVisible && (
				<div id='chatWidget' className={styles.chatWidget}>
					<div id='chatWidgetHeader' className={styles.chatWidgetHeader}>
						<span>Hi there! 👋</span>
						<span style={{ cursor: 'pointer' }} onClick={() => setWidgetVisible(false)}>
							&times;
						</span>
					</div>
					<p
						style={{
							fontSize: '14px',
							color: '#6c757d',
							padding: '0 20px',
							margin: '0 auto 20px',
							textAlign: 'center'
						}}>
						Let us know if we can help you with anything at all.
					</p>
					<div id='chatWidgetOptions' className={styles.chatWidgetOptions}>
						<div
							id='liveChat'
							className={styles.chatOption}
							onClick={() => {
								handleLiveChatClick();
							}}>
							<IC_LIVECHAT />
							<span>LiveChat</span>
						</div>
						<a href='https://m.me/112199680191257' target='_blank' className={styles.chatOption}>
							<IC_MESSENGER />
							<span>Messenger</span>
						</a>
						<a href='https://wa.me/8801810117100' target='_blank' className={styles.chatOption}>
							<IC_WHATSAPP />
							<span>WhatsApp</span>
						</a>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatWidget;
