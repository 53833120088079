const v1_reffer: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<mask
				id='mask0_990_25573'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='0'
				width='24'
				height='24'>
				<rect width='24' height='24' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_990_25573)'>
				<path
					d='M12.5 11.95C12.9833 11.4167 13.3542 10.8083 13.6125 10.125C13.8708 9.44167 14 8.73333 14 8C14 7.26667 13.8708 6.55833 13.6125 5.875C13.3542 5.19167 12.9833 4.58333 12.5 4.05C13.5 4.18333 14.3333 4.625 15 5.375C15.6667 6.125 16 7 16 8C16 9 15.6667 9.875 15 10.625C14.3333 11.375 13.5 11.8167 12.5 11.95ZM17.45 20C17.6333 19.7 17.7708 19.3792 17.8625 19.0375C17.9542 18.6958 18 18.35 18 18V17C18 16.4 17.8667 15.8292 17.6 15.2875C17.3333 14.7458 16.9833 14.2667 16.55 13.85C17.4 14.15 18.1875 14.5375 18.9125 15.0125C19.6375 15.4875 20 16.15 20 17V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H17.45ZM8 12C6.9 12 5.95833 11.6083 5.175 10.825C4.39167 10.0417 4 9.1 4 8C4 6.9 4.39167 5.95833 5.175 5.175C5.95833 4.39167 6.9 4 8 4C9.1 4 10.0417 4.39167 10.825 5.175C11.6083 5.95833 12 6.9 12 8C12 9.1 11.6083 10.0417 10.825 10.825C10.0417 11.6083 9.1 12 8 12ZM0 18V17.2C0 16.6333 0.145833 16.1125 0.4375 15.6375C0.729167 15.1625 1.11667 14.8 1.6 14.55C2.63333 14.0333 3.68333 13.6458 4.75 13.3875C5.81667 13.1292 6.9 13 8 13C9.1 13 10.1833 13.1292 11.25 13.3875C12.3167 13.6458 13.3667 14.0333 14.4 14.55C14.8833 14.8 15.2708 15.1625 15.5625 15.6375C15.8542 16.1125 16 16.6333 16 17.2V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18Z'
					fill='#718096'
				/>
				<path
					d='M23.2875 8.69114V9.57231H20.6034V11.3987C20.6034 11.5483 20.6252 11.6604 20.6688 11.7352C20.7125 11.8046 20.8107 11.8393 20.9634 11.8393C21.0889 11.8393 21.2117 11.8206 21.3317 11.7833C21.4517 11.7405 21.5608 11.6871 21.659 11.623C21.7572 11.5536 21.8363 11.4735 21.8963 11.3827C21.9563 11.2919 21.9864 11.1985 21.9864 11.1024C21.9864 11.0543 21.9645 11.0089 21.9209 10.9662C21.8772 10.9234 21.7927 10.9021 21.6672 10.9021C21.569 10.9021 21.4817 10.9101 21.4053 10.9261L21.2744 10.109C21.3726 10.0823 21.4817 10.0636 21.6017 10.053C21.7272 10.0423 21.8554 10.0369 21.9864 10.0369C22.3082 10.0369 22.5674 10.109 22.7638 10.2532C22.9602 10.3974 23.0584 10.6217 23.0584 10.9261C23.0584 11.2145 22.9902 11.4708 22.8538 11.6951C22.7174 11.9194 22.5374 12.1064 22.3137 12.2559C22.09 12.4054 21.8445 12.5202 21.5772 12.6004C21.3153 12.6751 21.0589 12.7125 20.808 12.7125C20.5788 12.7125 20.3852 12.6858 20.2269 12.6324C20.0742 12.579 19.9514 12.5096 19.8587 12.4241C19.7987 12.3654 19.7414 12.2986 19.6868 12.2238C19.6377 12.1491 19.5968 12.0449 19.5641 11.9114C19.5368 11.7779 19.5232 11.599 19.5232 11.3747V9.57231H18.8358V8.69114H23.2875ZM19.4413 6.7125C19.6214 6.7125 19.7796 6.73386 19.916 6.77659C20.0524 6.81931 20.1669 6.88607 20.2597 6.97685C20.3688 7.07832 20.4533 7.21984 20.5134 7.40142C20.5734 7.58299 20.6034 7.83934 20.6034 8.17044V8.72318H19.5232V8.2105C19.5232 8.07699 19.5123 7.96484 19.4904 7.87405C19.4686 7.78326 19.425 7.71651 19.3595 7.67378C19.294 7.62572 19.1958 7.60169 19.0649 7.60169C18.994 7.60169 18.9176 7.6097 18.8358 7.62572C18.7594 7.64174 18.6803 7.66577 18.5985 7.69781L18.2875 6.92879C18.5003 6.85402 18.6912 6.80062 18.8603 6.76858C19.0295 6.73119 19.2231 6.7125 19.4413 6.7125Z'
					fill='#718096'
				/>
			</g>
		</svg>
	);
};
export default v1_reffer;
