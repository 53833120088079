'use client';

import { JsxElement } from 'typescript';
import { ReactNode } from 'react';
import cls from 'classnames';
import { Oval } from 'react-loader-spinner';

interface Props {
	type?:
		| 'light'
		| 'primary'
		| 'secondary'
		| 'danger'
		| 'danger-outline'
		| 'confirmed'
		| 'success'
		| 'warning'
		| 'light'
		| 'register'
		| 'order'
		| 'general'
		| 'warning-outline'
		| 'grey'
		| 'primary-outline'
		| 'search-outline'
		| 'already-cart'
		| 'veriant'
		| 'veriant-active'
		| 'out-of-stock'
		| 'orang-outline'
		| 'primary-outline-cart'
		| 'cancel-order'
		| 'orange'
		| 'delivered';

	size?: 'small' | 'medium' | 'large';
	rounded?: boolean;
	label?: string | JsxElement | any; // Made this optional
	children?: ReactNode; // New prop for children
	icon?: ReactNode; // New prop for icon
	className?: string;
	onClick?: () => void;
	style?: any;
	lableClass?: any;
	disabled?: boolean;
	loading?: boolean;
	extraText?: string;
	endIcon?: ReactNode;
	loadingIcon?: ReactNode;
}

const checkJsx = (label: any, lableClass: any) => {
	if (typeof label === 'object') {
		return label;
	} else {
		return <span className={lableClass ? lableClass : 'text-wrap-nowrap'}>{label}</span>;
	}
};

const Button = ({
	type = 'primary',
	label,
	children,
	icon,
	className,
	onClick,
	style,
	lableClass,
	size = 'medium',
	rounded = false,
	disabled = false,
	loading = false,
	extraText,
	endIcon,
	loadingIcon
}: Props) => {
	const sizeClass = size;
	const roundedClass = rounded ? 'rounded' : ' ';
	const disabledClass = disabled || loading ? 'disabled' : '';
	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!disabled && onClick) {
			onClick();
		}
	};

	return (
		<div
			className={cls('arogga-btn', type, sizeClass, roundedClass, disabledClass, className)}
			style={style}
			onClick={handleClick}>
			<div className='inner'>
				{loading && loadingIcon ? (
					loadingIcon
				) : (
					<>
						{icon && <span className='icon'>{icon}</span>}
						{label ? checkJsx(label, lableClass) : children}
						{endIcon && <span className='end-icon'>{endIcon}</span>}
					</>
				)}
			</div>
		</div>
	);
};

export default Button;
