export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || 'Arogga';
export const TEL_NUMBER = process.env.NEXT_PUBLIC_TEL_NUMBER || '09610016778';

// Following must set in .env file
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

export const MAIN_WEB_URL = process.env.NEXT_PUBLIC_MAIN_WEB_URL || 'https://www.arogga.com';
export const SECOND_WEB_URL = process.env.NEXT_PUBLIC_SECOND_WEB_URL || 'https://m.arogga.com';

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://api.arogga.com';
export const LAB_TEST_API_BASE_URL = process.env.NEXT_PUBLIC_LT_BASE_URL || 'https://lab.arogga.com';

export const ASSET_CDN_URL = process.env.NEXT_PUBLIC_ASSET_CDN_URL;
export const SITE_MAP_API = process.env.NEXT_SITEMAP_API_KEY;
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION;
export const PIXELID = process.env.NEXT_PUBLIC_PIXELID;
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN;

export const userStorageName = 'arogga_user_info';
export const cartStorageName = 'arogga_cart_info';
export const locationStorageName = 'arogga_location_info';
export const partnerStorageName = 'arogga_partner_info';

//search storage name
export const searchStorageName = 'arogga_search_storage';

export const APP_DOWNLOAD_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.arogga.app';
export const APP_DOWNLOAD_LINK_ISO = 'https://apps.apple.com/us/app/arogga/id1499378347';

// Agora Signaling Config
export const agoraConfig = {
	appId: process.env.NEXT_PUBLIC_API_AGORA_APP_ID || 'f0215e6e86a34ff685b83d8419061013',
	appCertificate: process.env.NEXT_PUBLIC_API_AGORA_APP_CERTIFICATE || '04a975b1e60c4228bfc0da713b346518',
	channelName: process.env.NEXT_PUBLIC_API_AGORA_CHANNEL_NAME || 'test-channel',
	token: '',
	proxyUrl: 'http://localhost:8080/',
	serverUrl: '<URL to a token server>',
	tokenExpiryTime: 86400, //24 hours in seconds
	encryptionMode: 1,
	salt: '',
	cipherKey: '',
	presenceTimeout: 300,
	logUpload: false,
	logLevel: 'debug',
	cloudProxy: true,
	useStringUserId: false,
	rtcToken: ''
};
