export const v1_desc: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
		<g>
			<path
				d='M18.375 22.075L19.525 20.925L15.075 16.475L13.925 17.625C13.3083 18.2417 13 18.9833 13 19.85C13 20.7167 13.3083 21.4583 13.925 22.075C14.5417 22.6917 15.2833 23 16.15 23C17.0167 23 17.7583 22.6917 18.375 22.075ZM20.925 19.525L22.075 18.375C22.6917 17.7583 23 17.0167 23 16.15C23 15.2833 22.6917 14.5417 22.075 13.925C21.4583 13.3083 20.7167 13 19.85 13C18.9833 13 18.2417 13.3083 17.625 13.925L16.475 15.075L20.925 19.525ZM7 9H17V7H7V9ZM12 4.25C12.2167 4.25 12.3958 4.17917 12.5375 4.0375C12.6792 3.89583 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6792 3.10417 12.5375 2.9625C12.3958 2.82083 12.2167 2.75 12 2.75C11.7833 2.75 11.6042 2.82083 11.4625 2.9625C11.3208 3.10417 11.25 3.28333 11.25 3.5C11.25 3.71667 11.3208 3.89583 11.4625 4.0375C11.6042 4.17917 11.7833 4.25 12 4.25ZM11.125 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.2C9.41667 2.4 9.77917 1.91667 10.2875 1.55C10.7958 1.18333 11.3667 1 12 1C12.6333 1 13.2042 1.18333 13.7125 1.55C14.2208 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11.125C20.3 10.9583 19.6042 10.9458 18.9125 11.0875C18.2208 11.2292 17.5833 11.4917 17 11.875V11H7V13H15.725L13.725 15H7V17H11.875C11.4917 17.5833 11.2292 18.2208 11.0875 18.9125C10.9458 19.6042 10.9583 20.3 11.125 21Z'
				fill='currentColor'
			/>
		</g>
	</svg>
);

export default v1_desc;
