const v1_driving: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='76' height='75' viewBox='0 0 76 75' fill='none'>
			<rect opacity='0.1' x='0.109375' width='75.3876' height='75' rx='37.5' fill='#0E7673' />
			<mask id='mask0_218_21672' maskUnits='userSpaceOnUse' x='0' y='0' width='76' height='75'>
				<rect x='0.109375' width='75.3876' height='75' rx='37.5' fill='#F1F2F4' />
			</mask>
			<g mask='url(#mask0_218_21672)'>
				<path
					d='M66.0476 42.3977H40.0177C39.5526 42.3977 39.1738 42.0209 39.1738 41.5582V3.83956C39.1738 3.3768 39.5526 3 40.0177 3H66.0476C66.5127 3 66.8915 3.3768 66.8915 3.83956V41.5582C66.8915 42.0209 66.5127 42.3977 66.0476 42.3977ZM40.0177 3.03582C39.5727 3.03582 39.2098 3.39686 39.2098 3.83956V41.5582C39.2098 42.0009 39.5727 42.3619 40.0177 42.3619H66.0476C66.4925 42.3619 66.8554 42.0009 66.8554 41.5582V3.83956C66.8554 3.39686 66.4925 3.03582 66.0476 3.03582H40.0177Z'
					fill='#E0E0E0'
				/>
				<path
					d='M36.0295 42.3977H10.0011C9.53597 42.3977 9.15723 42.0209 9.15723 41.5582V3.83956C9.15723 3.3768 9.53597 3 10.0011 3H36.031C36.4961 3 36.8748 3.3768 36.8748 3.83956V41.5582C36.8734 42.0209 36.4961 42.3977 36.0295 42.3977ZM10.0011 3.03582C9.55613 3.03582 9.19323 3.39686 9.19323 3.83956V41.5582C9.19323 42.0009 9.55613 42.3619 10.0011 42.3619H36.031C36.4759 42.3619 36.8388 42.0009 36.8388 41.5582V3.83956C36.8388 3.39686 36.4759 3.03582 36.031 3.03582H10.0011Z'
					fill='#E0E0E0'
				/>
				<path d='M74.1242 47.5508H2.11914V47.5866H74.1242V47.5508Z' fill='#E0E0E0' />
				<path d='M11.8724 49.5938H9.55957V49.6296H11.8724V49.5938Z' fill='#E0E0E0' />
				<path d='M34.3593 49.207H26.6504V49.2428H34.3593V49.207Z' fill='#E0E0E0' />
				<path d='M17.237 50.9219H12.0166V50.9577H17.237V50.9219Z' fill='#E0E0E0' />
				<path d='M62.8243 49.1875H56.6045V49.2233H62.8243V49.1875Z' fill='#E0E0E0' />
				<path d='M65.02 49.1875H64.1084V49.2233H65.02V49.1875Z' fill='#E0E0E0' />
				<path d='M55.417 50.0781H47.6562V50.1139H55.417V50.0781Z' fill='#E0E0E0' />
				<path
					d='M65.4376 33.1234L65.3166 26.5072H61.5882V28.0159H58.8434V11.4883H53.5668V17.9942H51.9827V14.7448H47.8655V30.5876H42.059V24.99H38.417V12.8536H33.9239V20.9455H29.7015V38.3743H26.4296V29.887H23.0497V24.4255H19.4609V20.2335L15.456 22.3338V33.5518H11.2336V31.238H5.59277V47.4977L71.2556 47.5292V33.1234H65.4376Z'
					fill='white'
				/>
				<path
					d='M23.3262 47.5075V23.3164H18.8389V47.5304C20.2588 47.496 23.3262 47.5075 23.3262 47.5075Z'
					fill='#E6E6E6'
				/>
				<path d='M19.2388 47.5306V34.2266H10.0049V47.5019L19.2388 47.5306Z' fill='#FAFAFA' />
				<path d='M30.459 47.511V27.9805H25.2285V47.5096L30.459 47.511Z' fill='#E0E0E0' />
				<path d='M26.2544 28.7422H25.624V30.0431H26.2544V28.7422Z' fill='#FAFAFA' />
				<path d='M27.5239 28.7422H26.8936V30.0431H27.5239V28.7422Z' fill='#FAFAFA' />
				<path d='M28.7896 28.7422H28.1592V30.0431H28.7896V28.7422Z' fill='#FAFAFA' />
				<path d='M30.0601 28.7422H29.4297V30.0431H30.0601V28.7422Z' fill='#FAFAFA' />
				<path d='M26.2544 30.9258H25.624V32.2267H26.2544V30.9258Z' fill='#FAFAFA' />
				<path d='M27.5239 30.9258H26.8936V32.2267H27.5239V30.9258Z' fill='#FAFAFA' />
				<path d='M28.7896 30.9258H28.1592V32.2267H28.7896V30.9258Z' fill='#FAFAFA' />
				<path d='M30.0601 30.9258H29.4297V32.2267H30.0601V30.9258Z' fill='#FAFAFA' />
				<path d='M26.2544 33.1055H25.624V34.4064H26.2544V33.1055Z' fill='#FAFAFA' />
				<path d='M27.5239 33.1055H26.8936V34.4064H27.5239V33.1055Z' fill='#FAFAFA' />
				<path d='M28.7896 33.1055H28.1592V34.4064H28.7896V33.1055Z' fill='#FAFAFA' />
				<path d='M30.0601 33.1055H29.4297V34.4064H30.0601V33.1055Z' fill='#FAFAFA' />
				<path d='M26.2544 35.2891H25.624V36.59H26.2544V35.2891Z' fill='#FAFAFA' />
				<path d='M27.5239 35.2891H26.8936V36.59H27.5239V35.2891Z' fill='#FAFAFA' />
				<path d='M28.7896 35.2891H28.1592V36.59H28.7896V35.2891Z' fill='#FAFAFA' />
				<path d='M30.0601 35.2891H29.4297V36.59H30.0601V35.2891Z' fill='#FAFAFA' />
				<path d='M26.2544 37.4727H25.624V38.7735H26.2544V37.4727Z' fill='#FAFAFA' />
				<path d='M27.5239 37.4727H26.8936V38.7735H27.5239V37.4727Z' fill='#FAFAFA' />
				<path d='M28.7896 37.4727H28.1592V38.7735H28.7896V37.4727Z' fill='#FAFAFA' />
				<path d='M30.0601 37.4727H29.4297V38.7735H30.0601V37.4727Z' fill='#FAFAFA' />
				<path d='M26.2544 39.6523H25.624V40.9532H26.2544V39.6523Z' fill='#FAFAFA' />
				<path d='M27.5239 39.6523H26.8936V40.9532H27.5239V39.6523Z' fill='#FAFAFA' />
				<path d='M28.7896 39.6523H28.1592V40.9532H28.7896V39.6523Z' fill='#FAFAFA' />
				<path d='M30.0601 39.6523H29.4297V40.9532H30.0601V39.6523Z' fill='#FAFAFA' />
				<path d='M26.2544 41.832H25.624V43.1329H26.2544V41.832Z' fill='#FAFAFA' />
				<path d='M27.5239 41.832H26.8936V43.1329H27.5239V41.832Z' fill='#FAFAFA' />
				<path d='M28.7896 41.832H28.1592V43.1329H28.7896V41.832Z' fill='#FAFAFA' />
				<path d='M30.0601 41.832H29.4297V43.1329H30.0601V41.832Z' fill='#FAFAFA' />
				<path d='M26.2544 44.0156H25.624V45.3165H26.2544V44.0156Z' fill='#FAFAFA' />
				<path d='M27.5239 44.0156H26.8936V45.3165H27.5239V44.0156Z' fill='#FAFAFA' />
				<path d='M28.7896 44.0156H28.1592V45.3165H28.7896V44.0156Z' fill='#FAFAFA' />
				<path d='M30.0601 44.0156H29.4297V45.3165H30.0601V44.0156Z' fill='#FAFAFA' />
				<path
					d='M48.6354 47.5167V25.1953H37.79V47.5253C37.79 47.5253 48.5519 47.5282 48.6354 47.5167Z'
					fill='#F5F5F5'
				/>
				<path d='M39.9193 26.0859H38.6045V27.5845H39.9193V26.0859Z' fill='#E6E6E6' />
				<path d='M42.5492 26.0859H41.2344V27.5845H42.5492V26.0859Z' fill='#E6E6E6' />
				<path d='M45.18 26.0859H43.8652V27.5845H45.18V26.0859Z' fill='#E6E6E6' />
				<path d='M47.8109 26.0859H46.4961V27.5845H47.8109V26.0859Z' fill='#E6E6E6' />
				<path d='M39.9193 28.6016H38.6045V30.1002H39.9193V28.6016Z' fill='#E6E6E6' />
				<path d='M42.5492 28.6016H41.2344V30.1002H42.5492V28.6016Z' fill='#E6E6E6' />
				<path d='M45.18 28.6016H43.8652V30.1002H45.18V28.6016Z' fill='#E6E6E6' />
				<path d='M47.8109 28.6016H46.4961V30.1002H47.8109V28.6016Z' fill='#E6E6E6' />
				<path d='M39.9193 31.1133H38.6045V32.6119H39.9193V31.1133Z' fill='#E6E6E6' />
				<path d='M42.5492 31.1133H41.2344V32.6119H42.5492V31.1133Z' fill='#E6E6E6' />
				<path d='M45.18 31.1133H43.8652V32.6119H45.18V31.1133Z' fill='#E6E6E6' />
				<path d='M47.8109 31.1133H46.4961V32.6119H47.8109V31.1133Z' fill='#E6E6E6' />
				<path d='M39.9193 33.6289H38.6045V35.1275H39.9193V33.6289Z' fill='#E6E6E6' />
				<path d='M42.5492 33.6289H41.2344V35.1275H42.5492V33.6289Z' fill='#E6E6E6' />
				<path d='M45.18 33.6289H43.8652V35.1275H45.18V33.6289Z' fill='#E6E6E6' />
				<path d='M47.8109 33.6289H46.4961V35.1275H47.8109V33.6289Z' fill='#E6E6E6' />
				<path d='M39.9193 36.1406H38.6045V37.6392H39.9193V36.1406Z' fill='#E6E6E6' />
				<path d='M42.5492 36.1406H41.2344V37.6392H42.5492V36.1406Z' fill='#E6E6E6' />
				<path d='M45.18 36.1406H43.8652V37.6392H45.18V36.1406Z' fill='#E6E6E6' />
				<path d='M47.8109 36.1406H46.4961V37.6392H47.8109V36.1406Z' fill='#E6E6E6' />
				<path d='M39.9193 38.6523H38.6045V40.1509H39.9193V38.6523Z' fill='#E6E6E6' />
				<path d='M42.5492 38.6523H41.2344V40.1509H42.5492V38.6523Z' fill='#E6E6E6' />
				<path d='M45.18 38.6523H43.8652V40.1509H45.18V38.6523Z' fill='#E6E6E6' />
				<path d='M47.8109 38.6523H46.4961V40.1509H47.8109V38.6523Z' fill='#E6E6E6' />
				<path d='M39.9193 41.1719H38.6045V42.6705H39.9193V41.1719Z' fill='#E6E6E6' />
				<path d='M42.5492 41.1719H41.2344V42.6705H42.5492V41.1719Z' fill='#E6E6E6' />
				<path d='M45.18 41.1719H43.8652V42.6705H45.18V41.1719Z' fill='#E6E6E6' />
				<path d='M47.8109 41.1719H46.4961V42.6705H47.8109V41.1719Z' fill='#E6E6E6' />
				<path d='M39.9193 43.6836H38.6045V45.1822H39.9193V43.6836Z' fill='#E6E6E6' />
				<path d='M42.5492 43.6836H41.2344V45.1822H42.5492V43.6836Z' fill='#E6E6E6' />
				<path d='M45.18 43.6836H43.8652V45.1822H45.18V43.6836Z' fill='#E6E6E6' />
				<path d='M47.8109 43.6836H46.4961V45.1822H47.8109V43.6836Z' fill='#E6E6E6' />
				<path d='M20.8762 24.9609H19.665V26.3421H20.8762V24.9609Z' fill='#FAFAFA' />
				<path d='M20.8762 27.2734H19.665V28.6546H20.8762V27.2734Z' fill='#FAFAFA' />
				<path d='M20.8762 29.5938H19.665V30.9749H20.8762V29.5938Z' fill='#FAFAFA' />
				<path d='M20.8762 31.9062H19.665V33.2874H20.8762V31.9062Z' fill='#FAFAFA' />
				<path d='M20.8762 34.2266H19.665V35.6077H20.8762V34.2266Z' fill='#FAFAFA' />
				<path d='M20.8762 36.543H19.665V37.9241H20.8762V36.543Z' fill='#FAFAFA' />
				<path d='M20.8762 38.8594H19.665V40.2405H20.8762V38.8594Z' fill='#FAFAFA' />
				<path d='M20.8762 41.1758H19.665V42.5569H20.8762V41.1758Z' fill='#FAFAFA' />
				<path d='M22.4982 24.9609H21.2871V26.3421H22.4982V24.9609Z' fill='#FAFAFA' />
				<path d='M22.4982 27.2734H21.2871V28.6546H22.4982V27.2734Z' fill='#FAFAFA' />
				<path d='M22.4982 29.5938H21.2871V30.9749H22.4982V29.5938Z' fill='#FAFAFA' />
				<path d='M22.4982 31.9062H21.2871V33.2874H22.4982V31.9062Z' fill='#FAFAFA' />
				<path d='M22.4982 34.2266H21.2871V35.6077H22.4982V34.2266Z' fill='#FAFAFA' />
				<path d='M22.4982 36.543H21.2871V37.9241H22.4982V36.543Z' fill='#FAFAFA' />
				<path d='M22.4982 38.8594H21.2871V40.2405H22.4982V38.8594Z' fill='#FAFAFA' />
				<path d='M22.4982 41.1758H21.2871V42.5569H22.4982V41.1758Z' fill='#FAFAFA' />
				<path d='M36.267 14.4609H34.9521V15.9595H36.267V14.4609Z' fill='#FAFAFA' />
				<path d='M36.267 16.9727H34.9521V18.4713H36.267V16.9727Z' fill='#FAFAFA' />
				<path d='M36.267 19.3125H34.9521V20.8111H36.267V19.3125Z' fill='#FAFAFA' />
				<path d='M36.267 21.8281H34.9521V23.3267H36.267V21.8281Z' fill='#FAFAFA' />
				<path d='M36.267 24.3438H34.9521V25.8424H36.267V24.3438Z' fill='#FAFAFA' />
				<path d='M36.267 26.8555H34.9521V28.3541H36.267V26.8555Z' fill='#FAFAFA' />
				<path d='M36.267 29.3711H34.9521V30.8697H36.267V29.3711Z' fill='#FAFAFA' />
				<path d='M36.267 31.8828H34.9521V33.3814H36.267V31.8828Z' fill='#FAFAFA' />
				<path d='M56.0179 12.2812H54.7031V13.7799H56.0179V12.2812Z' fill='#FAFAFA' />
				<path d='M56.0179 14.793H54.7031V16.2916H56.0179V14.793Z' fill='#FAFAFA' />
				<path d='M56.0179 17.3086H54.7031V18.8072H56.0179V17.3086Z' fill='#FAFAFA' />
				<path d='M56.0179 19.8203H54.7031V21.3189H56.0179V19.8203Z' fill='#FAFAFA' />
				<path d='M56.0179 22.3359H54.7031V23.8345H56.0179V22.3359Z' fill='#FAFAFA' />
				<path d='M56.0179 24.8516H54.7031V26.3502H56.0179V24.8516Z' fill='#FAFAFA' />
				<path d='M56.0179 27.3633H54.7031V28.8619H56.0179V27.3633Z' fill='#FAFAFA' />
				<path d='M56.0179 29.8789H54.7031V31.3775H56.0179V29.8789Z' fill='#FAFAFA' />
				<path d='M65.4386 23.8281H57.5195V47.5292H65.4386V23.8281Z' fill='#E6E6E6' />
				<path d='M61.0119 24.9961H58.6904V26.4947H61.0119V24.9961Z' fill='#FAFAFA' />
				<path d='M61.0119 27.5117H58.6904V29.0103H61.0119V27.5117Z' fill='#FAFAFA' />
				<path d='M61.0119 30.0234H58.6904V31.522H61.0119V30.0234Z' fill='#FAFAFA' />
				<path d='M61.0119 32.5391H58.6904V34.0377H61.0119V32.5391Z' fill='#FAFAFA' />
				<path d='M61.0119 35.0508H58.6904V36.5494H61.0119V35.0508Z' fill='#FAFAFA' />
				<path d='M61.0119 37.5664H58.6904V39.065H61.0119V37.5664Z' fill='#FAFAFA' />
				<path d='M61.0119 40.0781H58.6904V41.5767H61.0119V40.0781Z' fill='#FAFAFA' />
				<path d='M61.0119 42.5938H58.6904V44.0924H61.0119V42.5938Z' fill='#FAFAFA' />
				<path d='M64.1173 24.9961H61.7959V26.4947H64.1173V24.9961Z' fill='#FAFAFA' />
				<path d='M64.1173 27.5117H61.7959V29.0103H64.1173V27.5117Z' fill='#FAFAFA' />
				<path d='M64.1173 30.0234H61.7959V31.522H64.1173V30.0234Z' fill='#FAFAFA' />
				<path d='M64.1173 32.5391H61.7959V34.0377H64.1173V32.5391Z' fill='#FAFAFA' />
				<path d='M64.1173 35.0508H61.7959V36.5494H64.1173V35.0508Z' fill='#FAFAFA' />
				<path d='M64.1173 37.5664H61.7959V39.065H64.1173V37.5664Z' fill='#FAFAFA' />
				<path d='M64.1173 40.0781H61.7959V41.5767H64.1173V40.0781Z' fill='#FAFAFA' />
				<path d='M64.1173 42.5938H61.7959V44.0924H64.1173V42.5938Z' fill='#FAFAFA' />
				<path d='M53.2004 47.5205V31.1133H49.7471V47.5177L53.2004 47.5205Z' fill='#E0E0E0' />
				<path d='M50.4257 31.7773H50.0088V32.8948H50.4257V31.7773Z' fill='#FAFAFA' />
				<path d='M51.2636 31.7773H50.8467V32.8948H51.2636V31.7773Z' fill='#FAFAFA' />
				<path d='M52.1005 31.7773H51.6836V32.8948H52.1005V31.7773Z' fill='#FAFAFA' />
				<path d='M52.9374 31.7773H52.5205V32.8948H52.9374V31.7773Z' fill='#FAFAFA' />
				<path d='M50.4257 33.6523H50.0088V34.7698H50.4257V33.6523Z' fill='#FAFAFA' />
				<path d='M51.2636 33.6523H50.8467V34.7698H51.2636V33.6523Z' fill='#FAFAFA' />
				<path d='M52.1005 33.6523H51.6836V34.7698H52.1005V33.6523Z' fill='#FAFAFA' />
				<path d='M52.9374 33.6523H52.5205V34.7698H52.9374V33.6523Z' fill='#FAFAFA' />
				<path d='M50.4257 35.5273H50.0088V36.6448H50.4257V35.5273Z' fill='#FAFAFA' />
				<path d='M51.2636 35.5273H50.8467V36.6448H51.2636V35.5273Z' fill='#FAFAFA' />
				<path d='M52.1005 35.5273H51.6836V36.6448H52.1005V35.5273Z' fill='#FAFAFA' />
				<path d='M52.9374 35.5273H52.5205V36.6448H52.9374V35.5273Z' fill='#FAFAFA' />
				<path d='M50.4257 37.3984H50.0088V38.5159H50.4257V37.3984Z' fill='#FAFAFA' />
				<path d='M51.2636 37.3984H50.8467V38.5159H51.2636V37.3984Z' fill='#FAFAFA' />
				<path d='M52.1005 37.3984H51.6836V38.5159H52.1005V37.3984Z' fill='#FAFAFA' />
				<path d='M52.9374 37.3984H52.5205V38.5159H52.9374V37.3984Z' fill='#FAFAFA' />
				<path d='M50.4257 39.2773H50.0088V40.3948H50.4257V39.2773Z' fill='#FAFAFA' />
				<path d='M51.2636 39.2773H50.8467V40.3948H51.2636V39.2773Z' fill='#FAFAFA' />
				<path d='M52.1005 39.2773H51.6836V40.3948H52.1005V39.2773Z' fill='#FAFAFA' />
				<path d='M52.9374 39.2773H52.5205V40.3948H52.9374V39.2773Z' fill='#FAFAFA' />
				<path d='M50.4257 41.1484H50.0088V42.2659H50.4257V41.1484Z' fill='#FAFAFA' />
				<path d='M51.2636 41.1484H50.8467V42.2659H51.2636V41.1484Z' fill='#FAFAFA' />
				<path d='M52.1005 41.1484H51.6836V42.2659H52.1005V41.1484Z' fill='#FAFAFA' />
				<path d='M52.9374 41.1484H52.5205V42.2659H52.9374V41.1484Z' fill='#FAFAFA' />
				<path d='M50.4257 43.0234H50.0088V44.1409H50.4257V43.0234Z' fill='#FAFAFA' />
				<path d='M51.2636 43.0234H50.8467V44.1409H51.2636V43.0234Z' fill='#FAFAFA' />
				<path d='M52.1005 43.0234H51.6836V44.1409H52.1005V43.0234Z' fill='#FAFAFA' />
				<path d='M52.9374 43.0234H52.5205V44.1409H52.9374V43.0234Z' fill='#FAFAFA' />
				<path d='M50.4257 44.8984H50.0088V46.0159H50.4257V44.8984Z' fill='#FAFAFA' />
				<path d='M51.2636 44.8984H50.8467V46.0159H51.2636V44.8984Z' fill='#FAFAFA' />
				<path d='M52.1005 44.8984H51.6836V46.0159H52.1005V44.8984Z' fill='#FAFAFA' />
				<path d='M52.9374 44.8984H52.5205V46.0159H52.9374V44.8984Z' fill='#FAFAFA' />
				<path d='M11.824 34.7812H10.7051V35.7197H11.824V34.7812Z' fill='#E6E6E6' />
				<path d='M14.0633 34.7812H12.9443V35.7197H14.0633V34.7812Z' fill='#E6E6E6' />
				<path d='M16.2996 34.7812H15.1807V35.7197H16.2996V34.7812Z' fill='#E6E6E6' />
				<path d='M18.5389 34.7812H17.4199V35.7197H18.5389V34.7812Z' fill='#E6E6E6' />
				<path d='M11.824 36.3516H10.7051V37.29H11.824V36.3516Z' fill='#E6E6E6' />
				<path d='M14.0633 36.3516H12.9443V37.29H14.0633V36.3516Z' fill='#E6E6E6' />
				<path d='M16.2996 36.3516H15.1807V37.29H16.2996V36.3516Z' fill='#E6E6E6' />
				<path d='M18.5389 36.3516H17.4199V37.29H18.5389V36.3516Z' fill='#E6E6E6' />
				<path d='M11.824 37.9258H10.7051V38.8642H11.824V37.9258Z' fill='#E6E6E6' />
				<path d='M14.0633 37.9258H12.9443V38.8642H14.0633V37.9258Z' fill='#E6E6E6' />
				<path d='M16.2996 37.9258H15.1807V38.8642H16.2996V37.9258Z' fill='#E6E6E6' />
				<path d='M18.5389 37.9258H17.4199V38.8642H18.5389V37.9258Z' fill='#E6E6E6' />
				<path d='M11.824 39.4961H10.7051V40.4345H11.824V39.4961Z' fill='#E6E6E6' />
				<path d='M14.0633 39.4961H12.9443V40.4345H14.0633V39.4961Z' fill='#E6E6E6' />
				<path d='M16.2996 39.4961H15.1807V40.4345H16.2996V39.4961Z' fill='#E6E6E6' />
				<path d='M18.5389 39.4961H17.4199V40.4345H18.5389V39.4961Z' fill='#E6E6E6' />
				<path d='M11.824 41.0703H10.7051V42.0087H11.824V41.0703Z' fill='#E6E6E6' />
				<path d='M14.0633 41.0703H12.9443V42.0087H14.0633V41.0703Z' fill='#E6E6E6' />
				<path d='M16.2996 41.0703H15.1807V42.0087H16.2996V41.0703Z' fill='#E6E6E6' />
				<path d='M18.5389 41.0703H17.4199V42.0087H18.5389V41.0703Z' fill='#E6E6E6' />
				<path d='M11.824 42.6406H10.7051V43.579H11.824V42.6406Z' fill='#E6E6E6' />
				<path d='M14.0633 42.6406H12.9443V43.579H14.0633V42.6406Z' fill='#E6E6E6' />
				<path d='M16.2996 42.6406H15.1807V43.579H16.2996V42.6406Z' fill='#E6E6E6' />
				<path d='M18.5389 42.6406H17.4199V43.579H18.5389V42.6406Z' fill='#E6E6E6' />
				<path d='M11.824 44.2109H10.7051V45.1494H11.824V44.2109Z' fill='#E6E6E6' />
				<path d='M14.0633 44.2109H12.9443V45.1494H14.0633V44.2109Z' fill='#E6E6E6' />
				<path d='M16.2996 44.2109H15.1807V45.1494H16.2996V44.2109Z' fill='#E6E6E6' />
				<path d='M18.5389 44.2109H17.4199V45.1494H18.5389V44.2109Z' fill='#E6E6E6' />
				<path
					d='M7.61426 15.1643C7.61426 14.5439 8.11974 14.0411 8.7433 14.0396C8.85275 14.0411 8.9622 14.0568 9.06876 14.0883C9.05004 13.9751 9.0414 13.8591 9.04284 13.7445C9.04284 12.4307 10.1128 11.3662 11.4334 11.3662C11.5803 11.3662 11.7272 11.3791 11.8726 11.4063C12.4717 10.4249 13.7563 10.1126 14.7428 10.7086C15.3577 11.0797 15.7364 11.7401 15.7465 12.4565H15.7811C16.8439 12.4565 17.7065 13.3147 17.7065 14.372C17.7065 15.4293 16.8439 16.2875 15.7811 16.2875H8.70874C8.09813 16.2703 7.61282 15.7717 7.61426 15.1643Z'
					fill='white'
				/>
				<path
					d='M42.2539 10.3668C42.2539 9.74642 42.7594 9.24354 43.3829 9.24211C43.4924 9.24354 43.6018 9.2593 43.7084 9.29082C43.6897 9.17764 43.681 9.06159 43.6825 8.94697C43.6825 7.63319 44.7525 6.5687 46.0731 6.5687C46.2199 6.5687 46.3668 6.58159 46.5123 6.60881C47.1114 5.62741 48.3959 5.31652 49.3824 5.91109C49.9973 6.28216 50.3761 6.94263 50.3862 7.65898H50.4207C51.4835 7.65898 52.3461 8.51716 52.3461 9.57449C52.3461 10.6318 51.4835 11.49 50.4207 11.49H43.3484C42.7378 11.4714 42.2525 10.9728 42.2539 10.3668Z'
					fill='white'
				/>
				<path
					d='M26.7393 10.4897C26.7393 10.2375 26.9452 10.0326 27.1986 10.0326C27.2433 10.0326 27.2879 10.0384 27.3297 10.0513C27.3239 10.0054 27.3211 9.95814 27.3211 9.91086C27.3211 9.37503 27.7574 8.94236 28.2946 8.94236C28.355 8.94236 28.4141 8.94809 28.4731 8.95955C28.7165 8.55983 29.2378 8.43088 29.6396 8.67301C29.8916 8.82487 30.0472 9.09422 30.05 9.38792H30.0659C30.4994 9.38792 30.8493 9.7375 30.8493 10.1687C30.8493 10.5986 30.4979 10.9481 30.0659 10.9481H27.1857C26.938 10.9395 26.7393 10.7375 26.7393 10.4897Z'
					fill='white'
				/>
				<path
					d='M40.374 18.2252C40.374 17.9114 40.6289 17.6578 40.9443 17.6578C40.999 17.6578 41.0552 17.665 41.1085 17.6807C41.1013 17.6234 41.097 17.5647 41.097 17.5074C41.097 16.8426 41.6384 16.3054 42.3052 16.3054C42.3801 16.3054 42.455 16.3125 42.527 16.3268C42.828 15.8311 43.4774 15.6721 43.9757 15.9715C44.2882 16.1592 44.4812 16.4945 44.4855 16.8584H44.5042C45.0414 16.8584 45.4777 17.2925 45.4763 17.8269C45.4763 18.3613 45.0414 18.7939 44.5042 18.7939H40.9285C40.6203 18.7839 40.374 18.5332 40.374 18.2252Z'
					fill='#F5F5F5'
				/>
				<path
					d='M22.5908 17.9523C22.5908 17.5411 22.9264 17.2073 23.3397 17.2073C23.4117 17.2073 23.4851 17.2173 23.5542 17.2374C23.5442 17.1615 23.5398 17.0855 23.5398 17.0082C23.5398 16.1357 24.2513 15.4279 25.1283 15.4279C25.2262 15.4279 25.3241 15.4379 25.4206 15.4551C25.8166 14.8033 26.6677 14.5941 27.323 14.9881C27.7334 15.2345 27.9869 15.6758 27.9926 16.1529H28.0171C28.7228 16.1529 29.2945 16.7231 29.2945 17.4251C29.2945 18.1271 28.7228 18.6959 28.0171 18.6959H23.321C22.9134 18.6873 22.5908 18.3563 22.5908 17.9523Z'
					fill='white'
				/>
				<path
					d='M64.1084 13.8865C64.1084 13.5928 64.3475 13.355 64.6427 13.355C64.6945 13.355 64.7464 13.3621 64.7953 13.3764C64.7881 13.322 64.7852 13.2676 64.7852 13.2131C64.7852 12.5913 65.2922 12.087 65.9172 12.087C65.9877 12.087 66.0569 12.0942 66.126 12.1071C66.4082 11.6429 67.016 11.4939 67.4826 11.7747C67.7749 11.9509 67.9563 12.2647 67.9592 12.6057H67.9765C68.4791 12.6057 68.8881 13.0125 68.8867 13.5126C68.8867 14.0126 68.4791 14.418 67.9765 14.418H64.6283C64.3388 14.4094 64.1084 14.1745 64.1084 13.8865Z'
					fill='white'
				/>
				<path
					d='M4.45359 65.7522C4.24478 65.9356 13.7307 50.9883 13.7307 50.9883H15.5582L7.66646 65.7537L4.45359 65.7522Z'
					fill='#E6E6E6'
				/>
				<path
					d='M72.4073 65.7522C72.6162 65.9356 63.1302 50.9883 63.1302 50.9883H61.3027L69.1945 65.7537L72.4073 65.7522Z'
					fill='#E6E6E6'
				/>
				<path
					d='M62.7906 61.0466C62.7906 65.4393 51.8819 68.9995 38.4241 68.9995C24.9664 68.9995 14.0576 65.4393 14.0576 61.0466C14.0576 56.654 24.9664 53.0938 38.4241 53.0938C51.8819 53.0938 62.7906 56.654 62.7906 61.0466Z'
					fill='#CCFBDA'
				/>
				<path
					d='M19.8522 65.6735H15.3936C14.5497 65.6735 13.8643 64.9929 13.8643 64.152V51.8379C13.8643 50.9984 14.5483 50.3164 15.3936 50.3164H19.8522C20.6961 50.3164 21.3816 50.9969 21.3816 51.8379V64.152C21.3816 64.9915 20.6975 65.6735 19.8522 65.6735Z'
					fill='#263238'
				/>
				<path
					d='M57.6173 65.6735H62.3725C63.2164 65.6735 63.9019 64.9929 63.9019 64.152V51.8379C63.9019 50.9984 63.2178 50.3164 62.3725 50.3164H57.6173C56.7734 50.3164 56.0879 50.9969 56.0879 51.8379V64.152C56.0879 64.9915 56.7719 65.6735 57.6173 65.6735Z'
					fill='#263238'
				/>
				<path
					d='M16.2677 42.2969C16.1266 42.3255 15.4497 43.0777 14.9039 43.7095C14.4244 44.264 14.1579 44.9803 14.1551 45.7239C14.1464 48.2225 14.2112 53.7169 14.3768 54.6625C14.5813 55.8244 15.6542 59.8947 15.9105 60.1053C16.1669 60.3159 17.9036 60.3689 17.9036 60.3689C17.9036 60.3689 18.1585 46.5763 17.9036 46.5233C17.6473 46.4703 16.2677 42.2969 16.2677 42.2969Z'
					fill='#3ED398'
				/>
				<path
					opacity='0.3'
					d='M16.2677 42.2969C16.1266 42.3255 15.4497 43.0777 14.9039 43.7095C14.4244 44.264 14.1579 44.9803 14.1551 45.7239C14.1464 48.2225 14.2112 53.7169 14.3768 54.6625C14.5813 55.8244 15.6542 59.8947 15.9105 60.1053C16.1669 60.3159 17.9036 60.3689 17.9036 60.3689C17.9036 60.3689 18.1585 46.5763 17.9036 46.5233C17.6473 46.4703 16.2677 42.2969 16.2677 42.2969Z'
					fill='black'
				/>
				<path
					d='M61.3923 42.2969C61.5392 42.3255 62.2419 43.0777 62.8108 43.7095C63.3091 44.264 63.5856 44.9803 63.5884 45.7239C63.5985 48.2225 63.5308 53.7169 63.358 54.6625C63.1449 55.8244 62.0302 59.8947 61.7638 60.1053C61.4989 60.3173 59.693 60.3689 59.693 60.3689C59.693 60.3689 59.428 46.5763 59.693 46.5233C59.9579 46.4703 61.3923 42.2969 61.3923 42.2969Z'
					fill='#3ED398'
				/>
				<path
					opacity='0.3'
					d='M61.3923 42.2969C61.5392 42.3255 62.2419 43.0777 62.8108 43.7095C63.3091 44.264 63.5856 44.9803 63.5884 45.7239C63.5985 48.2225 63.5308 53.7169 63.358 54.6625C63.1449 55.8244 62.0302 59.8947 61.7638 60.1053C61.4989 60.3173 59.693 60.3689 59.693 60.3689C59.693 60.3689 59.428 46.5763 59.693 46.5233C59.9579 46.4703 61.3923 42.2969 61.3923 42.2969Z'
					fill='black'
				/>
				<path
					d='M61.561 54.9399C61.561 54.9399 61.9426 43.8308 61.6042 41.729C61.3147 39.9353 56.2556 29.9107 54.7781 27.0123C54.3057 26.0854 53.6217 25.2859 52.7648 24.7272C52.1643 24.3361 51.4255 24.0151 50.5946 23.9894C49.3244 23.9507 41.45 23.9765 38.8506 23.9865C36.2512 23.9765 28.3767 23.9507 27.1066 23.9894C26.2756 24.0151 25.5369 24.3361 24.9363 24.7272C24.0795 25.2859 23.3954 26.0854 22.9231 27.0123C21.447 29.9107 16.3865 39.9353 16.097 41.729C15.7586 43.8308 16.1402 54.9399 16.1402 54.9399L15.5469 56.4285L16.7321 60.7194C16.7321 60.7194 22.661 61.3326 23.6762 61.3326C24.693 61.3326 25.5397 60.281 25.5397 60.281L38.0182 60.6678V60.7194L38.8506 60.6936L39.683 60.7194V60.6678L52.1614 60.281C52.1614 60.281 53.0082 61.3326 54.0249 61.3326C55.0416 61.3326 60.9691 60.7194 60.9691 60.7194L62.1543 56.4285L61.561 54.9399Z'
					fill='#3ED398'
				/>
				<path
					opacity='0.3'
					d='M59.9715 52.1133C52.4124 53.9987 41.8593 54.2824 38.8495 54.3254C35.8397 54.2824 25.2867 53.9987 17.7276 52.1133C17.7276 52.1133 16.8376 55.0374 17.7276 56.824C17.7276 56.824 30.844 59.0962 38.8495 58.8613C46.8536 59.0962 59.9715 56.824 59.9715 56.824C60.8615 55.0374 59.9715 52.1133 59.9715 52.1133Z'
					fill='black'
				/>
				<path
					d='M22.2842 37.0722L23.7185 33.7627C24.0123 33.0865 24.6618 32.6523 25.3775 32.6523H52.2282C52.9209 32.6523 53.5517 33.0592 53.8584 33.6996L55.4137 36.959L22.2842 37.0722Z'
					fill='#263238'
				/>
				<path
					opacity='0.5'
					d='M53.6817 27.6555C53.0394 26.1755 51.6094 25.2328 50.0426 25.2543H27.6562C26.0879 25.2328 24.6579 26.1769 24.0171 27.6555L20.208 36.435L38.0163 36.6284C38.0163 36.6385 38.0163 36.6471 38.0163 36.6471L38.8487 36.6385L39.6811 36.6471C39.6811 36.6471 39.6811 36.6399 39.6811 36.6284L57.4893 36.435L53.6817 27.6555Z'
					fill='white'
				/>
				<path
					d='M18.7571 41.5039C18.7571 41.5039 17.052 42.3578 16.6617 43.418C16.2715 44.4782 16.306 46.4252 16.6617 47.0857C17.0174 47.7462 19.3734 48.4167 21.5984 48.6645C22.3026 48.7433 25.3023 48.9697 25.612 48.6645C25.7401 48.537 26.0022 46.4238 25.2922 45.5069C24.5808 44.5899 18.7571 41.5039 18.7571 41.5039Z'
					fill='white'
				/>
				<path
					d='M18.7064 35.5764C18.7064 35.5764 17.923 34.211 17.6465 34.1322C17.37 34.0534 14.6568 34.2425 14.1456 34.3013C13.967 34.3213 13.3478 34.5778 13.44 35.1967C13.5321 35.8156 13.6704 36.8156 14.2997 37.1179C14.929 37.4202 15.1508 37.34 16.0681 37.3715C16.9855 37.403 18.3219 37.3242 18.4285 37.2125C18.535 37.1007 18.5509 36.9432 18.5509 36.9432L18.98 37.2454L19.4553 36.2297L18.7064 35.5764Z'
					fill='#3ED398'
				/>
				<path
					d='M19.8151 55.8793C20.4379 55.8793 20.9427 55.3572 20.9427 54.7131C20.9427 54.069 20.4379 53.5469 19.8151 53.5469C19.1923 53.5469 18.6875 54.069 18.6875 54.7131C18.6875 55.3572 19.1923 55.8793 19.8151 55.8793Z'
					fill='#A24E3F'
				/>
				<path
					d='M19.7617 37.6562C19.7617 37.6562 18.9884 38.9829 18.5938 40.0575C18.3749 40.652 18.5895 41.3182 19.1137 41.675C19.1137 41.675 24.167 44.4959 25.3493 45.4458'
					stroke='#263238'
					strokeMiterlimit='10'
				/>
				<path
					d='M24.167 36.6678C24.19 36.536 24.6725 34.129 24.9058 32.9642C24.9792 32.6003 25.273 32.341 25.6157 32.3396C27.2258 32.3339 31.703 32.3195 33.2483 32.3353C33.5579 32.3382 33.807 32.6118 33.807 32.9485L34.2088 36.6678H24.167Z'
					fill='#263238'
				/>
				<path
					d='M31.3383 31.7049H27.9209C27.2153 31.7049 26.6436 31.1361 26.6436 30.4341V29.0716C26.6436 28.3696 27.2153 27.8008 27.9209 27.8008H31.3383C32.0439 27.8008 32.6157 28.3696 32.6157 29.0716V30.4341C32.6157 31.1361 32.0439 31.7049 31.3383 31.7049Z'
					fill='#263238'
				/>
				<g opacity='0.2'>
					<path
						d='M24.167 36.6678C24.19 36.536 24.6725 34.129 24.9058 32.9642C24.9792 32.6003 25.273 32.341 25.6157 32.3396C27.2258 32.3339 31.703 32.3195 33.2483 32.3353C33.5579 32.3382 33.807 32.6118 33.807 32.9485L34.2088 36.6678H24.167Z'
						fill='white'
					/>
					<path
						d='M31.3383 31.7049H27.9209C27.2153 31.7049 26.6436 31.1361 26.6436 30.4341V29.0716C26.6436 28.3696 27.2153 27.8008 27.9209 27.8008H31.3383C32.0439 27.8008 32.6157 28.3696 32.6157 29.0716V30.4341C32.6157 31.1361 32.0439 31.7049 31.3383 31.7049Z'
						fill='white'
					/>
				</g>
				<path
					opacity='0.3'
					d='M19.0903 37.3258L18.5517 36.9375C18.5344 37.0177 18.4998 37.1653 18.3659 37.2384C18.2406 37.3057 18.0217 37.3014 17.8691 37.3157L17.666 37.757C17.666 37.757 18.0764 37.6023 18.3745 37.4619C18.6726 37.3215 18.6482 37.1553 18.6482 37.1553L19.0903 37.3258Z'
					fill='black'
				/>
				<path
					opacity='0.3'
					d='M18.9169 35.0898L18.6963 35.5555C18.8596 35.7575 19.3911 36.3635 19.3911 36.3635L18.9169 35.0898Z'
					fill='black'
				/>
				<path
					d='M58.8162 41.5039C58.8162 41.5039 60.5876 42.3578 60.9937 43.418C61.3998 44.4782 61.3623 46.4252 60.9937 47.0857C60.625 47.7462 58.1754 48.4167 55.8626 48.6645C55.131 48.7433 52.0118 48.9697 51.6906 48.6645C51.5567 48.537 51.2845 46.4238 52.0233 45.5069C52.7635 44.5899 58.8162 41.5039 58.8162 41.5039Z'
					fill='white'
				/>
				<path
					d='M58.8699 35.5764C58.8699 35.5764 59.6836 34.211 59.9716 34.1322C60.2582 34.0534 63.0793 34.2425 63.6107 34.3013C63.7965 34.3213 64.4402 34.5778 64.3452 35.1967C64.2501 35.8156 64.1061 36.8156 63.4523 37.1179C62.7985 37.4202 62.5681 37.34 61.6133 37.3715C60.66 37.403 59.2717 37.3242 59.1594 37.2125C59.047 37.1007 59.0312 36.9432 59.0312 36.9432L58.5848 37.2454L58.0908 36.2297L58.8699 35.5764Z'
					fill='#3ED398'
				/>
				<path
					opacity='0.3'
					d='M39.2326 46.5839V46.5768C39.2326 46.5768 38.9187 46.5825 38.383 46.5839C37.8473 46.5825 37.5333 46.5768 37.5333 46.5768V46.5839C34.4573 46.5725 28.0359 46.4263 25.3486 45.4492C25.3486 45.4492 26.0672 47.8776 26.6663 48.5653C27.2654 49.253 27.9437 49.7387 29.5004 49.9006C30.7893 50.0353 34.5854 50.1771 37.5348 50.2674H37.5362C38.1482 50.286 38.7243 50.2545 39.2326 50.2688C42.2266 50.2344 45.9723 50.0353 47.267 49.9006C48.8237 49.7387 49.502 49.253 50.1011 48.5653C50.7002 47.8776 51.4188 45.4492 51.4188 45.4492C48.7315 46.4263 42.3087 46.5725 39.2326 46.5839Z'
					fill='black'
				/>
				<path
					d='M58.5453 55.5356C59.0031 55.0801 59.0031 54.3417 58.5453 53.8863C58.0875 53.4309 57.3453 53.4309 56.8875 53.8863C56.4297 54.3417 56.4297 55.0801 56.8875 55.5356C57.3453 55.991 58.0875 55.991 58.5453 55.5356Z'
					fill='#A24E3F'
				/>
				<path
					d='M57.9135 37.5625C57.9135 37.5625 58.5658 38.9665 58.9791 40.0382C59.211 40.6414 58.9892 41.3233 58.4463 41.6786C58.4463 41.6786 53.1251 44.7532 51.2861 45.4953'
					stroke='#263238'
					strokeMiterlimit='10'
				/>
				<path
					d='M52.4143 36.323C52.3898 36.1869 51.8829 33.704 51.6381 32.5006C51.5617 32.1252 51.2521 31.8573 50.8907 31.8558C49.1971 31.8501 44.4894 31.8344 42.865 31.8516C42.5395 31.8544 42.2774 32.1367 42.2774 32.4848L41.8555 36.323H52.4143Z'
					fill='#263238'
				/>
				<path
					opacity='0.2'
					d='M52.4143 36.323C52.3898 36.1869 51.8829 33.704 51.6381 32.5006C51.5617 32.1252 51.2521 31.8573 50.8907 31.8558C49.1971 31.8501 44.4894 31.8344 42.865 31.8516C42.5395 31.8544 42.2774 32.1367 42.2774 32.4848L41.8555 36.323H52.4143Z'
					fill='white'
				/>
				<path
					d='M45.1348 31.4578H49.1397C49.8454 31.4578 50.4171 30.889 50.4171 30.187V28.5208C50.4171 27.8188 49.8454 27.25 49.1397 27.25H45.1348C44.4291 27.25 43.8574 27.8188 43.8574 28.5208V30.187C43.8574 30.889 44.4291 31.4578 45.1348 31.4578Z'
					fill='#263238'
				/>
				<path
					opacity='0.2'
					d='M45.1348 31.4578H49.1397C49.8454 31.4578 50.4171 30.889 50.4171 30.187V28.5208C50.4171 27.8188 49.8454 27.25 49.1397 27.25H45.1348C44.4291 27.25 43.8574 27.8188 43.8574 28.5208V30.187C43.8574 30.889 44.4291 31.4578 45.1348 31.4578Z'
					fill='white'
				/>
				<path
					opacity='0.3'
					d='M58.4717 37.3258L59.0319 36.9375C59.0506 37.0177 59.0866 37.1653 59.2249 37.2384C59.3545 37.3057 59.582 37.3014 59.7418 37.3157L59.9521 37.757C59.9521 37.757 59.5258 37.6023 59.2148 37.4619C58.9037 37.3215 58.9296 37.1553 58.9296 37.1553L58.4717 37.3258Z'
					fill='black'
				/>
				<path
					opacity='0.3'
					d='M58.6452 35.0898L58.8744 35.5555C58.7054 35.7575 58.1523 36.3635 58.1523 36.3635L58.6452 35.0898Z'
					fill='black'
				/>
				<path
					opacity='0.2'
					d='M39.1008 38.2922C38.8949 38.2922 21.2162 38.0673 20.5955 38.2134C20.1937 38.308 19.8078 39.351 19.5875 40.0731C19.4622 40.4828 19.6206 40.9269 19.972 41.1576C21.4193 42.1089 25.3997 44.4958 29.7171 45.1634C35.1089 45.9958 38.3102 45.4084 39.2405 44.6075C40.1708 43.8081 39.3082 38.2922 39.1008 38.2922Z'
					fill='white'
				/>
				<path
					d='M44.0402 32.2461C44.0719 32.2934 44.7156 35.1043 44.7156 35.1043L44.0488 37.2419H41.2637C41.2637 37.2419 42.8636 33.3736 43.1675 32.875C43.4699 32.3822 44.0402 32.2461 44.0402 32.2461Z'
					fill='#3ED398'
				/>
				<path
					d='M50.234 32.2461C50.2023 32.2934 49.5586 35.1043 49.5586 35.1043L50.2254 37.2419H53.0105C53.0105 37.2419 51.4106 33.3736 51.1067 32.875C50.8057 32.3822 50.234 32.2461 50.234 32.2461Z'
					fill='#3ED398'
				/>
				<path
					d='M50.3102 32.245C49.6852 32.2035 46.5948 31.7063 44.04 32.245L44.0501 37.2408H50.7596L50.3102 32.245Z'
					fill='#3ED398'
				/>
				<path
					d='M48.9068 27.6522C48.9068 27.6522 50.262 26.8928 49.4584 26.0776C48.8233 25.4358 48.2617 25.5289 47.4134 25.6822C45.3325 26.059 45.4535 26.9215 45.4535 26.9215L48.9068 27.6522Z'
					fill='#263238'
				/>
				<path
					d='M46.2569 31.9971C46.2569 31.9971 46.3678 31.2034 45.9012 29.8266C45.9012 29.8252 45.9732 29.7893 45.9732 29.7879C45.9084 29.5959 45.8407 29.4183 45.7788 29.2635C45.7773 29.2592 45.7773 29.2578 45.7773 29.2578L47.746 30.3538C47.72 30.8008 47.9188 31.6561 48.0311 32.0158C48.0311 32.0158 48.3018 32.6648 47.4219 32.7307C46.7782 32.7794 46.2569 31.9971 46.2569 31.9971Z'
					fill='#B97964'
				/>
				<path
					opacity='0.2'
					d='M47.8046 30.9804L46.6914 30.7812C46.8066 31.1179 47.1278 31.5091 47.8968 31.5091C47.8968 31.5076 47.8032 31.1294 47.8046 30.9804Z'
					fill='black'
				/>
				<path
					d='M45.7217 28.3294C45.9031 29.5329 45.9823 30.2148 46.652 30.7764C47.66 31.6203 49.08 30.9512 49.2629 29.7148C49.4285 28.6016 49.103 26.8208 47.8674 26.4554C46.652 26.0958 45.5402 27.1274 45.7217 28.3294Z'
					fill='#B97964'
				/>
				<path
					d='M45.4378 26.8901C46.9225 25.4674 48.4332 26.2654 49.0121 27.0448L46.4214 27.0749C46.3076 27.0735 46.2443 27.1895 46.2687 27.4044C46.2687 27.4044 47.1674 29.0062 45.9289 29.9059C45.4853 29.7368 45.396 29.0047 45.2232 28.5778C44.9654 27.9445 44.64 27.0104 45.4378 26.8901Z'
					fill='#263238'
				/>
				<path
					d='M45.3299 29.3804C45.4551 29.6827 45.7086 29.899 45.9606 29.9979C46.3394 30.1455 46.4402 29.7386 46.371 29.3603C46.3091 29.0194 46.1723 28.611 45.7734 28.6254C45.3817 28.6383 45.1873 29.0337 45.3299 29.3804Z'
					fill='#B97964'
				/>
				<path
					d='M47.354 28.5054C47.3655 28.6286 47.44 28.7232 47.5202 28.7146C47.6019 28.706 47.6577 28.6 47.6477 28.4753C47.6362 28.3521 47.5617 28.2576 47.4815 28.2662C47.3998 28.2748 47.3425 28.3808 47.354 28.5054Z'
					fill='#263238'
				/>
				<path
					d='M48.6118 28.4664C48.6233 28.5896 48.6978 28.6841 48.778 28.6755C48.8597 28.6669 48.9155 28.5609 48.9055 28.4363C48.8941 28.3131 48.8196 28.2185 48.7393 28.2271C48.6577 28.2357 48.6003 28.3432 48.6118 28.4664Z'
					fill='#263238'
				/>
				<path
					d='M48.3105 28.4102C48.3105 28.4102 48.7561 28.9904 48.9051 29.2297C48.7461 29.4001 48.4438 29.3543 48.4438 29.3543L48.3105 28.4102Z'
					fill='#A24E3F'
				/>
				<path
					d='M47.1348 27.9692C47.1549 27.9749 47.1763 27.9706 47.1921 27.9563C47.3827 27.7786 47.5918 27.8259 47.5933 27.8259C47.6248 27.8331 47.6563 27.8144 47.6635 27.7829C47.6706 27.7514 47.652 27.7199 47.6205 27.7127C47.609 27.7099 47.3483 27.6497 47.1119 27.8703C47.089 27.8918 47.0875 27.9291 47.109 27.952C47.1176 27.9606 47.1262 27.9663 47.1348 27.9692Z'
					fill='#263238'
				/>
				<path
					d='M48.0695 29.7855C47.869 29.8041 47.7042 29.7181 47.5352 29.582'
					stroke='#263238'
					strokeWidth='0.75'
					strokeMiterlimit='10'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					opacity='0.2'
					d='M45.5781 29.0194C45.6211 28.9435 45.7171 28.9077 45.8045 28.9205C45.8905 28.9334 45.9664 28.9879 46.0237 29.0538C46.0925 29.1326 46.1412 29.23 46.1627 29.3332'
					stroke='black'
					strokeWidth='0.5'
					strokeMiterlimit='10'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M49.2051 27.8721C49.2151 27.8693 49.2252 27.8621 49.2323 27.8535C49.2524 27.8292 49.2495 27.7919 49.2252 27.7718C48.9773 27.567 48.7223 27.6486 48.7108 27.6501C48.6807 27.6601 48.6635 27.6931 48.6721 27.7231C48.6822 27.7532 48.7137 27.7704 48.7438 27.7604C48.7538 27.7561 48.9515 27.6973 49.1507 27.8607C49.1664 27.8736 49.1865 27.8779 49.2051 27.8721Z'
					fill='#263238'
				/>
				<g opacity='0.2'>
					<path
						d='M47.4372 29.3047C47.3312 29.4207 47.2739 29.5798 47.2825 29.7374C47.2825 29.7374 47.2109 29.6127 47.2424 29.4823C47.2753 29.3505 47.4372 29.3047 47.4372 29.3047Z'
						fill='black'
					/>
				</g>
				<path
					d='M47.8491 31.3657L48.5504 31.9932L47.3796 33.4072L45.6875 31.9874L46.1887 31.2539L47.2471 32.1322L47.8491 31.3657Z'
					fill='#3ED398'
				/>
				<path
					opacity='0.8'
					d='M47.8491 31.3657L48.5504 31.9932L47.3796 33.4072L45.6875 31.9874L46.1887 31.2539L47.2471 32.1322L47.8491 31.3657Z'
					fill='white'
				/>
				<path
					d='M43.6963 37.4953C43.6963 35.4809 45.338 33.8477 47.3628 33.8477C49.3876 33.8477 51.0293 35.4809 51.0293 37.4953'
					stroke='#263238'
					strokeWidth='4'
					strokeMiterlimit='10'
				/>
				<path
					d='M44.582 34.2063C44.582 34.2063 44.1298 33.9068 44.0607 34.3023C44.0607 34.3023 43.5812 34.3023 43.5956 34.7378C43.5956 34.7378 43.2125 34.7923 43.3767 35.2292C43.3767 35.2292 43.0757 35.3109 43.2802 35.6934C43.4861 36.0745 43.8836 36.4427 44.0881 36.4026C44.294 36.3611 44.294 36.0888 44.294 36.0888C44.294 36.0888 44.8427 36.2393 44.7318 35.7894C44.7318 35.7894 44.896 35.9255 45.0054 35.7078C45.1149 35.49 45.0054 35.3811 45.0054 35.3811C45.0054 35.3811 45.1422 35.5201 45.2387 35.2536C45.3367 34.9842 44.8153 34.3983 44.582 34.2063Z'
					fill='#B97964'
				/>
				<path
					d='M49.6825 33.9563C49.6825 33.9563 50.1347 33.6568 50.2038 34.0523C50.2038 34.0523 50.6833 34.0523 50.6689 34.4878C50.6689 34.4878 51.052 34.5423 50.8878 34.9792C50.8878 34.9792 51.1888 35.0609 50.9843 35.4434C50.7784 35.8245 50.3809 36.1927 50.1764 36.1526C49.9705 36.1111 49.9705 35.8388 49.9705 35.8388C49.9705 35.8388 49.4218 35.9893 49.5327 35.5394C49.5327 35.5394 49.3685 35.6755 49.2591 35.4577C49.1496 35.24 49.2591 35.1311 49.2591 35.1311C49.2591 35.1311 49.1223 35.2701 49.0258 35.0036C48.9279 34.7342 49.4492 34.1468 49.6825 33.9563Z'
					fill='#B97964'
				/>
				<path
					d='M57.4991 36.3766L38.0433 36.5084L20.2509 36.3594L19.6475 37.7462L38.0433 37.782L58.1025 37.7462L57.4991 36.3766Z'
					fill='#263238'
				/>
				<path
					d='M36.4668 46.5625L36.7231 47.882C38.4124 48.1628 40.0742 48.1814 41.703 47.882L41.9233 46.5625C40.3162 46.6155 38.4642 46.5783 36.4668 46.5625Z'
					fill='#263238'
				/>
				<path
					d='M40.3522 26.6475C40.3248 26.4526 40.2974 26.242 40.2686 26.093C40.2456 25.9727 40.139 25.8867 40.0166 25.8867H36.5201C36.3976 25.8867 36.2911 25.9727 36.268 26.093C36.2392 26.2435 36.2119 26.4526 36.1845 26.6475C36.1442 26.9269 36.3616 27.1776 36.6453 27.1776H37.7845H38.7537H39.8928C40.175 27.1776 40.3925 26.9269 40.3522 26.6475Z'
					fill='#263238'
				/>
				<path d='M38.49 25.2578H38.043V26.0429H38.49V25.2578Z' fill='#263238' />
				<path
					opacity='0.3'
					d='M22.4258 31.35C22.4229 31.3572 36.69 25.2539 36.69 25.2539H40.6359L21.3486 33.8343L22.4258 31.35Z'
					fill='white'
				/>
				<path
					opacity='0.3'
					d='M20.7937 35.0879L44.4373 25.2539H50.0436L23.4421 36.4089L20.2422 36.3602L20.7937 35.0879Z'
					fill='white'
				/>
			</g>
		</svg>
	);
};

export default v1_driving;
