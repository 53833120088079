const v1_liver: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='76' height='75' viewBox='0 0 76 75' fill='none'>
			<rect opacity='0.1' x='0.109375' width='75.3876' height='75' rx='37.5' fill='#38BDF8' />
			<path
				d='M35.3657 55.4553C35.0991 56.2416 33.5536 56.4602 32.4055 56.4812C31.0962 56.5044 29.6325 55.9089 29.539 54.9599C29.4384 53.9387 30.355 53.6549 30.355 53.6549C30.355 53.6549 30.4088 53.5433 30.507 53.3339C31.1406 52.0103 33.6658 46.7928 36.4763 41.8009C38.0475 39.0072 39.71 36.2833 41.1783 34.3479C42.628 32.4382 42.642 29.0397 42.2282 25.9064C41.7348 22.1636 40.6289 18.8 40.6289 18.8C40.6289 18.8 40.6242 18.3581 40.8393 17.9114C41.0965 17.3764 41.6693 16.8414 42.9343 17.0438C42.9343 17.0438 44.3021 19.9817 45.0644 23.7594C45.1181 24.0292 45.1696 24.306 45.2187 24.5828C45.7307 27.5719 45.8009 30.9704 44.5313 33.8199C43.257 36.6787 41.5197 39.833 39.8736 42.9105C38.7981 44.9249 37.7576 46.9068 36.9112 48.7491C35.9666 50.8077 35.2604 52.6942 35.0103 54.262C35.0103 54.262 35.6229 54.6831 35.361 55.4553H35.3657Z'
				fill='#1B77AF'
			/>
			<g opacity='0.7'>
				<path
					d='M36.3575 49.1835C36.3575 49.1835 34.8283 52.4239 34.501 53.6963C34.501 53.6963 35.1159 50.2001 35.5719 49.0486C36.0301 47.8972 36.5866 48.6253 36.3575 49.1835Z'
					fill='white'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M42.5135 18.3237C42.5135 18.3237 43.7691 20.6359 44.0637 21.5501C44.0637 21.5501 43.4581 19.0309 43.0746 18.2074C42.6912 17.384 42.3218 17.926 42.5111 18.3237H42.5135Z'
					fill='white'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M36.9163 48.7466C36.9163 48.7466 35.9811 46.7113 34.2251 47.5719C34.2251 47.5719 31.922 52.2335 30.5098 53.3338C31.1434 52.0102 33.6686 46.7927 36.4791 41.8008L39.8788 42.908C38.8032 44.9225 37.7627 46.9043 36.9163 48.7466Z'
					fill='#606060'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M42.2302 25.909C41.7368 22.1662 40.6309 18.8026 40.6309 18.8026C40.6309 18.8026 40.6262 18.3607 40.8413 17.9141C41.4866 18.7817 42.0946 19.9238 42.1133 21.166C42.1133 21.166 44.4093 22.0034 45.0687 23.762C45.1435 23.962 45.1996 24.176 45.2254 24.3993C45.2324 24.4645 45.2324 24.525 45.223 24.5854C45.1155 25.3065 43.8248 25.702 42.2325 25.909H42.2302Z'
					fill='#606060'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M34.7117 55.088C34.7117 55.5276 33.6174 56.2208 32.3454 56.1906C31.0735 56.158 30.1031 55.4136 30.1055 54.974C30.1055 54.5343 31.1413 54.204 32.4132 54.2366C33.6852 54.2692 34.714 54.6506 34.714 55.088H34.7117Z'
					fill='#606060'
				/>
			</g>
			<path
				d='M40.0834 56.8898C37.2519 58.0645 35.863 57.4202 36.2769 55.6546C36.333 55.4104 36.3915 55.1801 36.4499 54.9591C37.0625 52.6795 37.7733 51.4629 38.1287 48.1202C38.2293 47.1595 38.933 44.6752 39.9104 41.5326C41.3881 36.7873 43.4995 30.5439 45.1385 25.7823C46.5461 21.6837 47.6029 18.6876 47.6029 18.6876C47.9139 18.5829 48.2015 18.5108 48.4681 18.4666C51.2411 18.0014 51.5428 20.5648 51.5428 20.5648C51.5428 20.5648 50.4134 22.6141 48.9263 25.5078C48.5826 26.1801 48.2179 26.8989 47.8438 27.6456C47.0698 29.1948 46.2538 30.8789 45.4822 32.5584C44.5376 34.6147 43.7029 37.4526 43.0646 40.3509C42.4309 43.2237 41.9914 46.157 41.83 48.4529C41.6804 50.586 41.7739 52.1701 42.1761 52.6469C43.5088 54.2333 42.3117 55.964 40.0834 56.8875V56.8898Z'
				fill='#D92E52'
			/>
			<g opacity='0.7'>
				<path
					d='M41.2641 48.9897C41.2641 48.9897 40.9547 51.6136 41.0175 52.5743C41.0175 52.5743 40.65 50.0062 40.7012 49.0967C40.7524 48.1872 41.292 48.5454 41.2618 48.9897H41.2641Z'
					fill='white'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M50.9323 20.7802C50.9323 20.7802 49.5154 22.9318 49.1553 23.8065C49.1553 23.8065 49.9386 21.3687 50.3805 20.5964C50.8224 19.8218 51.1521 20.4057 50.9323 20.7802Z'
					fill='white'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M48.9255 25.51C48.5818 26.1822 48.2171 26.901 47.843 27.6477C46.8446 27.0731 45.7503 26.259 45.1377 25.7845C46.5453 21.6858 47.6021 18.6897 47.6021 18.6897C47.9131 18.5851 48.2007 18.5129 48.4672 18.4688C48.0908 20.0366 47.3192 23.0745 46.8633 23.6933C46.8633 23.6933 48.009 24.4795 48.9255 25.51Z'
					fill='#606060'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M38.492 51.9859C38.2138 53.6607 36.4952 54.9331 36.4531 54.9633C37.0657 52.6837 37.7765 51.4671 38.1319 48.1245C38.2325 47.1638 38.9362 44.6795 39.9136 41.5368L42.9696 40.2109L43.0678 40.3552C42.4341 43.2279 41.9946 46.1612 41.8332 48.4571C40.3204 47.6104 39.2168 48.8177 39.2168 48.8177C39.2168 48.8177 38.7773 50.2901 38.4943 51.9859H38.492Z'
					fill='#606060'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M39.846 56.6054C38.394 57.2543 37.0636 57.4102 36.8789 56.9566C36.6942 56.5007 37.7206 55.6074 39.175 54.9584C40.627 54.3095 41.9574 54.1536 42.1421 54.6072C42.3268 55.0631 41.3004 55.9564 39.846 56.6054Z'
					fill='#606060'
				/>
			</g>
			<path
				d='M66.1555 42.9799C63.5274 46.2644 53.0782 43.0404 50.0058 43.9383C46.6249 44.9245 43.0171 47.8834 39.089 47.3158C35.1608 46.7459 29.8556 43.5893 26.7271 44.7966C23.7389 45.948 9.67254 51.0609 8.26263 46.4621C8.19482 46.2435 8.15508 46.0039 8.1504 45.741C8.14338 45.4944 12.6233 27.7972 24.3258 22.4633C37.7913 16.327 47.981 25.8223 47.981 25.8223C47.981 25.8223 66.3426 32.3285 66.6956 41.7866C66.7143 42.2658 66.4197 42.6496 66.1579 42.9799H66.1555Z'
				fill='#D92E52'
			/>
			<g opacity='0.4'>
				<path
					d='M66.1556 42.9784C63.5275 46.2629 53.0783 43.0389 50.0059 43.9368C46.6249 44.923 43.0171 47.8819 39.089 47.3143C35.1609 46.7444 29.8556 43.5878 26.7272 44.7951C23.739 45.9466 9.67261 51.0594 8.2627 46.4606C9.72171 46.2862 12.1043 45.5255 14.6529 42.7714C16.0464 41.264 17.5499 39.6334 19.0907 38.1284C22.1397 35.1533 25.3476 32.6713 28.1885 32.6108C31.8758 32.5317 35.825 35.7976 39.9237 37.3282C40.2745 37.4608 40.6252 37.5794 40.9783 37.6794C41.2892 37.7702 41.6026 37.8493 41.9135 37.9121C45.0677 38.5517 50.3449 36.2535 56.665 37.7213C59.6345 38.4099 62.8377 39.9312 66.1556 42.9784Z'
					fill='#606060'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M22.6586 26.0324C22.6586 26.0324 30.6621 19.4262 38.3313 22.8689C38.6376 23.0061 38.848 23.2922 38.8854 23.6156C38.9649 24.2855 38.2986 24.8019 37.6392 24.5739C35.1303 23.7086 29.5375 22.5642 22.6562 26.0324H22.6586Z'
					fill='white'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M56.5863 30.7669C58.5808 31.937 64.4215 35.7332 65.3264 40.5809C65.3264 40.5809 62.4505 35.175 55.9083 31.9882C55.5412 31.809 55.3916 31.3787 55.5763 31.0321C55.7657 30.6785 56.2263 30.5576 56.584 30.7669H56.5863Z'
					fill='white'
				/>
			</g>
			<path
				d='M56.6655 37.7261C52.8122 37.0516 47.5747 40.9199 43.9692 41.006C40.3638 41.0921 36.5807 37.7308 32.4164 36.1141C28.2498 34.4975 19.0889 38.1286 19.0889 38.1286C22.1378 35.1534 25.3458 32.6714 28.1867 32.611C31.8739 32.5319 35.8231 35.7978 39.9219 37.3284C39.9102 37.2772 39.5805 35.6605 41.2733 31.3711C42.9919 27.0166 47.9792 25.8233 47.9792 25.8233C50.2005 25.5627 49.7001 26.4955 49.7001 26.4955C40.9483 28.8496 40.9764 37.6796 40.9764 37.6796C41.2874 37.7703 41.6007 37.8494 41.9117 37.9122C45.0658 38.5519 50.3431 36.2537 56.6631 37.7215L56.6655 37.7261Z'
				fill='#D92E52'
			/>
			<g>
				<path
					d='M56.6655 37.7261C52.8122 37.0516 47.5747 40.9199 43.9692 41.006C40.3638 41.0921 36.5807 37.7308 32.4164 36.1141C28.2498 34.4975 19.0889 38.1286 19.0889 38.1286C22.1378 35.1534 25.3458 32.6714 28.1867 32.611C31.8739 32.5319 35.8231 35.7978 39.9219 37.3284C39.9102 37.2772 39.5805 35.6605 41.2733 31.3711C42.9919 27.0166 47.9792 25.8233 47.9792 25.8233C50.2005 25.5627 49.7001 26.4955 49.7001 26.4955C40.9483 28.8496 40.9764 37.6796 40.9764 37.6796C41.2874 37.7703 41.6007 37.8494 41.9117 37.9122C45.0658 38.5519 50.3431 36.2537 56.6631 37.7215L56.6655 37.7261Z'
					fill='white'
				/>
			</g>
			<g opacity='0.3'>
				<path
					d='M43.0614 33.6947C43.0802 34.0506 43.1854 34.3995 43.3444 34.7205C43.5034 35.0415 43.7021 35.3439 43.9336 35.6184C44.1627 35.8929 44.4082 36.1534 44.6724 36.3953C44.8081 36.5186 44.9203 36.6326 45.0419 36.7489C45.1471 36.8745 45.2617 36.9954 45.3388 37.1327C45.4791 37.356 45.5703 37.5979 45.589 37.8422C45.7199 37.8259 45.8532 37.8096 45.9865 37.791C45.9584 37.4816 45.8415 37.1839 45.6685 36.9373C45.5703 36.7745 45.4394 36.6395 45.3131 36.5023C45.1775 36.379 45.0395 36.2464 44.9086 36.1394C44.8525 36.0929 44.7964 36.0464 44.7426 35.9975C44.8712 35.9719 44.9974 35.9347 45.1237 35.8859C45.3669 35.7882 45.5937 35.623 45.7386 35.3997C45.8859 35.1764 45.9257 34.9066 45.9257 34.6647C45.921 34.439 45.9491 34.2157 46.0075 33.9994C46.0707 33.7854 46.1712 33.5807 46.3138 33.4109C46.4588 33.2411 46.6529 33.1085 46.875 33.0503C47.0995 32.9852 47.3426 33.0131 47.5718 33.0759C47.8056 33.1364 48.016 33.2573 48.2241 33.3783C48.0254 33.2434 47.8196 33.1085 47.5858 33.034C47.3566 32.9549 47.1018 32.9107 46.8539 32.9642C46.6084 33.0108 46.3816 33.1434 46.2109 33.3201C46.0403 33.4993 45.914 33.7133 45.8322 33.9435C45.7527 34.1738 45.7082 34.4134 45.6942 34.6553C45.6778 34.8787 45.6287 35.0834 45.5072 35.2462C45.278 35.5649 44.8174 35.6858 44.4059 35.6812C44.3217 35.5974 44.2399 35.5137 44.158 35.4253C43.9266 35.1741 43.7231 34.8996 43.5571 34.6088C43.5197 34.5344 43.473 34.4623 43.4426 34.3855L43.3467 34.1552L43.2742 33.918C43.2532 33.8365 43.2438 33.7551 43.2275 33.6737C43.1807 33.3434 43.2134 33.0084 43.2953 32.6828C43.3794 32.3548 43.5338 32.0501 43.6974 31.75C43.501 32.0291 43.3186 32.3269 43.2017 32.6549C43.0848 32.9805 43.0287 33.3341 43.0521 33.69L43.0614 33.6947Z'
					fill='#606060'
				/>
				<path
					d='M47.2089 31.4521C47.5012 31.4125 47.8075 31.4241 48.0764 31.5428C48.3429 31.6544 48.565 31.8498 48.7217 32.0871C48.876 32.322 48.9719 32.5942 49.0186 32.871C49.0607 33.1501 49.0631 33.4316 49.0256 33.7084C48.9906 34.0085 49.0046 34.3504 49.1542 34.6528C49.3016 34.9552 49.5587 35.2018 49.8463 35.3646C49.996 35.446 50.1456 35.5158 50.3023 35.5693C50.2298 35.6182 50.1526 35.6693 50.0778 35.7159C49.9025 35.8229 49.7131 35.9648 49.526 36.0927C49.353 36.2416 49.1706 36.3858 49.0256 36.5696C48.8059 36.8161 48.6305 37.1209 48.5393 37.4535C48.7217 37.4326 48.9064 37.4116 49.0911 37.393C49.1683 37.2092 49.2758 37.0348 49.4068 36.872C49.5213 36.7138 49.6803 36.5835 49.83 36.4463C49.996 36.323 50.1526 36.202 50.3374 36.0718C50.6998 35.8182 51.0411 35.5368 51.3638 35.239C51.6888 34.9389 51.9787 34.5993 52.2219 34.2271C52.4651 33.855 52.6451 33.4409 52.7199 33.0036C52.7994 32.5686 52.7807 32.1197 52.6825 31.694C52.5843 31.266 52.3996 30.8682 52.1939 30.4844C52.3552 30.8845 52.5048 31.2915 52.5609 31.7126C52.6171 32.1313 52.6077 32.5523 52.5048 32.9547C52.4744 33.0548 52.4511 33.1525 52.4136 33.2502L52.2897 33.5339L52.1377 33.8038C52.0886 33.8945 52.0185 33.9736 51.9647 34.0597C51.7192 34.3923 51.4293 34.697 51.1043 34.9668C50.992 35.0599 50.8775 35.1506 50.7606 35.239C50.2508 35.1715 49.6967 34.9413 49.4582 34.5039C49.3296 34.2783 49.2969 34.0155 49.3086 33.7363C49.3273 33.4339 49.3062 33.1269 49.2384 32.8291C49.1683 32.5291 49.0444 32.2406 48.8573 31.9894C48.6702 31.7405 48.4107 31.5381 48.1114 31.4358C47.8121 31.3264 47.4895 31.3357 47.1949 31.3939C46.8932 31.4451 46.6197 31.5777 46.3555 31.7079C46.6314 31.5939 46.9096 31.48 47.2066 31.4474L47.2089 31.4521Z'
					fill='#606060'
				/>
				<path
					d='M61.4584 39.5684C61.5192 39.601 61.5776 39.6359 61.6384 39.6684C61.8442 39.3823 62.0008 39.0636 62.1037 38.7357C62.2347 38.3216 62.2744 37.8889 62.2884 37.4609C62.2276 37.8843 62.1505 38.3053 61.9868 38.6938C61.8535 39.0125 61.6758 39.3079 61.4561 39.5661L61.4584 39.5684Z'
					fill='#606060'
				/>
				<path
					d='M35.261 30.1239C35.0201 30.0727 34.7746 30.089 34.5361 30.1099C34.777 30.1053 35.0201 30.1053 35.2516 30.1681C35.4808 30.2262 35.7076 30.3239 35.8689 30.4891C36.0349 30.6496 36.1378 30.8589 36.1799 31.0776C36.222 31.2962 36.2079 31.5242 36.1565 31.7405C36.1004 31.9569 36.0139 32.1662 35.8993 32.3593C35.78 32.5686 35.6842 32.8245 35.7005 33.092C35.7146 33.3572 35.8315 33.6131 35.9951 33.8178C36.0817 33.9225 36.1728 34.0155 36.2711 34.1016C36.2009 34.1179 36.1284 34.1318 36.0583 34.1434C35.8923 34.1714 35.7076 34.2179 35.5299 34.2598C35.3545 34.3179 35.1745 34.3714 35.0084 34.4644C34.8635 34.5342 34.7255 34.6273 34.5993 34.7343C34.7255 34.8017 34.8541 34.8692 34.9827 34.9367C35.0505 34.8855 35.123 34.8413 35.2002 34.7994C35.3358 34.718 35.4948 34.6692 35.6491 34.611C35.8128 34.5691 35.9648 34.5249 36.1448 34.4831C36.4932 34.404 36.8369 34.297 37.1712 34.1714C37.5079 34.0457 37.8306 33.8806 38.1276 33.6805C38.4245 33.4782 38.6887 33.2269 38.8804 32.9269C39.0768 32.6291 39.2008 32.2942 39.2616 31.9522C39.32 31.6103 39.3083 31.2614 39.2756 30.9217C39.2709 31.2637 39.2545 31.6056 39.168 31.9313C39.0792 32.2569 38.9412 32.564 38.7378 32.8268C38.684 32.8897 38.6349 32.9571 38.5765 33.0176L38.3964 33.1897L38.1977 33.3433C38.1322 33.3944 38.0574 33.434 37.9873 33.4805C37.6997 33.6526 37.3887 33.7922 37.0637 33.8969C36.9515 33.9318 36.8369 33.9643 36.7223 33.9969C36.3622 33.7968 36.0232 33.4665 35.9788 33.0757C35.9531 32.8734 36.0115 32.6687 36.1074 32.4686C36.2173 32.2523 36.2944 32.0197 36.3389 31.7801C36.3809 31.5405 36.3786 31.2916 36.3155 31.052C36.2547 30.8147 36.1237 30.5891 35.932 30.4263C35.7426 30.2588 35.4995 30.1704 35.261 30.1262V30.1239Z'
					fill='#606060'
				/>
				<path
					d='M57.432 35.8607C57.1421 35.7607 56.8335 35.7444 56.5342 35.7305C56.8335 35.763 57.1351 35.7979 57.4157 35.9119C57.6916 36.0189 57.9558 36.1724 58.1311 36.405C58.3112 36.6284 58.4047 36.9028 58.4211 37.182C58.4374 37.4588 58.3837 37.7379 58.2855 37.9985C58.2644 38.052 58.2387 38.1055 58.213 38.159C58.2901 38.1845 58.365 38.2078 58.4421 38.2357C58.4632 38.1822 58.4842 38.1287 58.5029 38.0729C58.5941 37.7821 58.6315 37.4727 58.5941 37.168C58.5567 36.8633 58.4304 36.5656 58.22 36.3353C58.0119 36.098 57.7243 35.9538 57.4344 35.8631L57.432 35.8607Z'
					fill='#606060'
				/>
				<path
					d='M53.5787 35.6395C53.6418 35.9767 53.726 36.3071 53.8359 36.6327C53.892 36.8002 53.9318 36.9467 53.9809 37.1003C53.9949 37.1701 54.0089 37.2422 54.023 37.3119C54.1516 37.3236 54.2778 37.3352 54.4088 37.3492C54.3901 37.2375 54.362 37.1282 54.3386 37.0189C54.2825 36.8514 54.2287 36.6792 54.1679 36.5304C54.1422 36.4652 54.1188 36.4001 54.0954 36.335C54.217 36.3722 54.3433 36.3978 54.4766 36.4164C54.7314 36.4466 55.0073 36.4094 55.2412 36.2861C55.475 36.1652 55.6433 35.9558 55.7602 35.7534C55.8654 35.5627 55.9987 35.3882 56.153 35.2324C56.312 35.0812 56.4967 34.9556 56.7025 34.8765C56.9106 34.7997 57.1397 34.7765 57.3595 34.83C57.5817 34.8788 57.7804 35.0114 57.9441 35.1696C58.1148 35.3254 58.2363 35.5231 58.3556 35.7232C58.2504 35.5185 58.1405 35.3115 57.9745 35.1417C57.8155 34.9695 57.6191 34.816 57.3806 34.7485C57.1468 34.6764 56.8896 34.6834 56.6557 34.7555C56.4219 34.8276 56.2092 34.9509 56.0268 35.1068C55.8467 35.2649 55.6901 35.4464 55.5615 35.6441C55.4399 35.8255 55.2973 35.9744 55.1126 36.0558C54.7595 36.2187 54.3059 36.1117 53.9552 35.9186C53.9248 35.8093 53.8944 35.6999 53.8663 35.5906C53.7892 35.2742 53.7494 34.9509 53.7494 34.6276C53.7541 34.5485 53.7494 34.4671 53.7588 34.388L53.7892 34.1484L53.8429 33.9158C53.864 33.839 53.8967 33.7646 53.9224 33.6878C54.044 33.3901 54.2334 33.1202 54.4625 32.8853C54.694 32.648 54.9746 32.4619 55.2622 32.2852C54.9559 32.4317 54.6566 32.5969 54.3947 32.8202C54.1352 33.0412 53.9154 33.3133 53.7634 33.6227C53.6068 33.9297 53.5273 34.274 53.5062 34.616C53.4875 34.9602 53.5109 35.3045 53.5764 35.6418L53.5787 35.6395Z'
					fill='#606060'
				/>
				<path
					d='M30.6384 28.8801C30.6664 29.3221 30.7529 29.7617 30.8886 30.1827C31.0218 30.6038 31.1808 31.0155 31.3726 31.4156C31.4708 31.6203 31.5456 31.8017 31.6298 31.9925C31.6929 32.1856 31.7654 32.3763 31.7888 32.5717C31.8355 32.8508 31.8332 33.13 31.7677 33.3882C31.9244 33.451 32.081 33.5161 32.2377 33.5836C32.3429 33.2253 32.3476 32.8462 32.2728 32.4973C32.2377 32.2646 32.1488 32.0506 32.0717 31.8343C31.9735 31.6296 31.8776 31.4156 31.7771 31.2342C31.735 31.1551 31.6929 31.076 31.6531 30.9946C31.8145 31.0248 31.9805 31.0388 32.1512 31.0411C32.4808 31.0411 32.8269 30.955 33.1051 30.762C33.3857 30.5712 33.5634 30.2804 33.6827 30.0036C33.7879 29.7431 33.9282 29.4989 34.1012 29.2779C34.2789 29.0592 34.4963 28.8708 34.7465 28.7429C34.999 28.6126 35.289 28.5521 35.5766 28.587C35.8688 28.6149 36.1401 28.7568 36.3762 28.9336C36.6194 29.1081 36.8041 29.343 36.9888 29.5803C36.8228 29.3337 36.6498 29.0848 36.4136 28.8917C36.1845 28.6963 35.9086 28.5289 35.5953 28.4754C35.2843 28.4172 34.957 28.4637 34.6717 28.5893C34.3841 28.7149 34.1339 28.9057 33.9258 29.1313C33.7224 29.3593 33.5517 29.6152 33.4185 29.8873C33.2922 30.1362 33.1355 30.3502 32.9111 30.4805C32.4879 30.741 31.8916 30.6689 31.4146 30.4782C31.3585 30.3432 31.3024 30.2083 31.2486 30.0711C31.1013 29.6756 30.9985 29.2686 30.947 28.8568C30.94 28.7545 30.919 28.6498 30.9213 28.5475V28.2381L30.954 27.931C30.9681 27.8287 30.9985 27.731 31.0195 27.6286C31.1271 27.2285 31.3258 26.8587 31.5807 26.5214C31.8379 26.1841 32.1675 25.9026 32.5066 25.6328C32.1395 25.8654 31.7817 26.1213 31.4871 26.4446C31.1925 26.7656 30.954 27.1471 30.8091 27.5658C30.6594 27.9822 30.6103 28.4335 30.6384 28.8755V28.8801Z'
					fill='#606060'
				/>
				<path
					d='M17.186 36.4663C16.9264 36.4151 16.6973 36.2686 16.4822 36.0778C16.253 35.8661 15.9958 35.6824 15.7176 35.5381C15.437 35.3939 15.1284 35.3009 14.8127 35.2753C14.4971 35.252 14.1744 35.3148 13.9055 35.4707C13.632 35.6219 13.4379 35.8685 13.2999 36.129C13.1526 36.3872 13.0872 36.678 13.0264 36.9617C13.1059 36.6826 13.19 36.4011 13.349 36.1569C13.501 35.915 13.6998 35.6917 13.9616 35.5684C14.2165 35.4404 14.5111 35.4032 14.7964 35.4428C15.0793 35.48 15.3552 35.5847 15.6054 35.7312C15.8532 35.8824 16.0777 36.0639 16.2764 36.2732C16.4939 36.4942 16.7721 36.7082 17.1065 36.7919C17.4385 36.8803 17.7962 36.8478 18.1049 36.7384C18.2639 36.6803 18.4112 36.6128 18.5514 36.5314C18.5444 36.6198 18.5374 36.7059 18.5281 36.7943C18.5047 36.9966 18.4977 37.2292 18.4836 37.4526C18.493 37.6782 18.4953 37.9085 18.5491 38.1364C18.5748 38.2737 18.6122 38.4109 18.6637 38.5458C18.7946 38.4156 18.9256 38.2853 19.0565 38.1574C19.0471 38.1225 19.0378 38.0853 19.0284 38.0481C18.9747 37.8596 18.97 37.6549 18.9536 37.4549C18.9606 37.2502 18.9606 37.0548 18.9723 36.8315C18.998 36.3965 18.9863 35.9592 18.9513 35.5219C18.9162 35.0822 18.8273 34.6449 18.6824 34.2215C18.5398 33.7982 18.32 33.3934 18.0207 33.0538C17.7237 32.7096 17.3543 32.4327 16.9545 32.228C16.5523 32.0233 16.1198 31.9001 15.6895 31.8047C16.1104 31.9443 16.5266 32.0978 16.896 32.3304C17.2655 32.5607 17.5928 32.8421 17.8477 33.1818C17.9061 33.2702 17.9716 33.3516 18.0254 33.4423L18.1727 33.7191L18.2919 34.0075C18.334 34.1029 18.3527 34.2053 18.3878 34.3053C18.5 34.7054 18.5608 35.1218 18.5725 35.5381C18.5772 35.6824 18.5748 35.8266 18.5725 35.9731C18.2007 36.3104 17.6723 36.571 17.1766 36.4686L17.186 36.4663Z'
					fill='#606060'
				/>
				<path
					d='M12.8244 41.5884C13.1494 41.7582 13.4932 41.8861 13.8462 41.9768C14.1969 42.0675 14.5523 42.1373 14.9101 42.1792C15.0013 42.1908 15.0854 42.2025 15.1673 42.2141C15.2655 42.1071 15.366 41.9978 15.4666 41.8908C15.2889 41.8698 15.1041 41.8419 14.9428 41.8326C14.8703 41.8279 14.7978 41.821 14.7254 41.814C14.8119 41.7186 14.889 41.6163 14.9592 41.5046C15.0925 41.286 15.1696 41.0184 15.1439 40.7533C15.1229 40.4858 14.9872 40.2438 14.8376 40.0484C14.695 39.8693 14.5804 39.6716 14.4915 39.4623C14.4074 39.2506 14.3629 39.0273 14.3723 38.8063C14.3817 38.583 14.4541 38.3643 14.5944 38.1899C14.7324 38.0084 14.9405 37.8875 15.1603 37.8084C15.3824 37.7223 15.6232 37.699 15.8617 37.6758C15.6209 37.6804 15.3754 37.6897 15.1439 37.7665C14.9124 37.834 14.6856 37.9479 14.5219 38.1317C14.3559 38.3131 14.2601 38.5504 14.2343 38.7923C14.2086 39.0366 14.2437 39.2831 14.3185 39.5181C14.398 39.7507 14.5103 39.9717 14.6505 40.1764C14.7768 40.3671 14.8633 40.5602 14.868 40.7649C14.882 41.158 14.5944 41.5209 14.2671 41.7558C14.1478 41.7349 14.0309 41.7163 13.914 41.693C13.575 41.6232 13.2453 41.5186 12.9367 41.3767C12.8618 41.3371 12.78 41.3069 12.7075 41.2627L12.4877 41.1301L12.2843 40.9789C12.2189 40.9254 12.1604 40.8649 12.0973 40.8068C11.8588 40.5648 11.6741 40.2764 11.5384 39.9624C11.4028 39.6483 11.3374 39.3111 11.2812 38.9714C11.2976 39.3134 11.3374 39.66 11.4473 39.9926C11.5572 40.3253 11.7302 40.6439 11.9687 40.9184C12.2025 41.1952 12.5018 41.4185 12.8268 41.5884H12.8244Z'
					fill='#606060'
				/>
				<path
					d='M29.4697 30.3159C29.465 30.4206 29.4673 30.5229 29.4556 30.6253L29.4089 30.93L29.3294 31.2278C29.3037 31.3278 29.2569 31.4208 29.2242 31.5185C29.0699 31.9 28.8664 32.2652 28.6209 32.6048C28.6186 32.6095 28.6139 32.6141 28.6116 32.6188C28.7519 32.6258 28.8945 32.6374 29.0348 32.6514C29.2288 32.3257 29.3949 31.9814 29.5164 31.6209C29.6591 31.2022 29.7269 30.7556 29.6895 30.3113C29.6567 29.867 29.5258 29.4366 29.3247 29.0435C29.1213 28.6504 28.8431 28.3038 28.5508 27.9805C28.808 28.334 29.0535 28.6946 29.2148 29.09C29.3738 29.4855 29.472 29.8972 29.472 30.3136L29.4697 30.3159Z'
					fill='#606060'
				/>
				<path
					d='M23.2168 30.5521C23.453 30.3753 23.6938 30.2008 23.972 30.0985C24.2432 29.9915 24.5425 29.931 24.8325 29.9821C25.1177 30.0263 25.3819 30.1659 25.5924 30.3613C25.8005 30.5544 25.9618 30.7963 26.0764 31.0545C26.1863 31.3174 26.2587 31.5895 26.2938 31.871C26.3359 32.171 26.4341 32.5014 26.6539 32.7619C26.6773 32.7898 26.703 32.8177 26.7287 32.8433C26.8713 32.8038 27.014 32.7689 27.1566 32.7386C27.0677 32.6851 26.9836 32.6223 26.9134 32.5479C26.7311 32.3595 26.6352 32.1106 26.5767 31.8361C26.5183 31.536 26.4201 31.2429 26.2821 30.9684C26.1395 30.6939 25.9478 30.4404 25.7046 30.2403C25.4614 30.0403 25.1598 29.9054 24.8442 29.8751C24.5285 29.8403 24.2199 29.924 23.9486 30.0496C23.6704 30.1706 23.4389 30.3636 23.2168 30.5544V30.5521Z'
					fill='#606060'
				/>
				<path
					d='M18.3796 31.1291C18.5245 31.5524 18.77 31.9432 19.0717 32.2805C19.3733 32.6201 19.717 32.9202 20.0888 33.1784C20.4582 33.4366 20.844 33.6739 21.2415 33.8809C21.4472 33.9879 21.6203 34.0902 21.805 34.1926C21.9757 34.3112 22.1534 34.4206 22.2937 34.5624C22.441 34.6974 22.5672 34.8462 22.6701 35.0044C22.8034 34.9044 22.9366 34.809 23.0699 34.7137C22.9437 34.5252 22.7917 34.3578 22.6233 34.2159C22.448 34.0507 22.2446 33.9297 22.0481 33.8041C21.8424 33.6971 21.632 33.5808 21.4402 33.4948C21.356 33.4575 21.2742 33.4157 21.1947 33.3761C21.3397 33.304 21.4776 33.2179 21.6132 33.1202C21.8704 32.9248 22.0879 32.6504 22.1838 32.3317C22.282 32.013 22.2399 31.6734 22.1557 31.3803C22.0739 31.1105 22.0318 30.829 22.0248 30.5499C22.0248 30.2684 22.0762 29.9893 22.1908 29.738C22.3053 29.4845 22.4924 29.2635 22.7402 29.1216C22.9857 28.9727 23.2874 28.9239 23.5843 28.9285C23.8836 28.9262 24.1782 29.0053 24.4705 29.0844C24.1852 28.9844 23.893 28.8867 23.5867 28.8727C23.285 28.8495 22.9647 28.8797 22.6865 29.0239C22.4082 29.1612 22.1814 29.3914 22.0388 29.6613C21.8938 29.9311 21.819 30.2335 21.798 30.5382C21.7816 30.843 21.8096 31.1477 21.8775 31.4454C21.9359 31.7199 21.9476 31.9851 21.8564 32.2224C21.6904 32.6829 21.1807 32.976 20.685 33.104C20.5564 33.0295 20.4278 32.9551 20.2992 32.876C19.9344 32.648 19.5977 32.3805 19.2985 32.0805C19.2283 32.0014 19.1465 31.9316 19.0834 31.8478L18.8869 31.5989L18.7186 31.3338C18.6648 31.243 18.6274 31.1453 18.5807 31.0523C18.4123 30.6685 18.3351 30.2521 18.3211 29.8311C18.3094 29.4077 18.3889 28.9867 18.4848 28.5703C18.3445 28.9727 18.2276 29.3914 18.1995 29.8264C18.1715 30.2591 18.2252 30.7057 18.3749 31.1267L18.3796 31.1291Z'
					fill='#606060'
				/>
			</g>
		</svg>
	);
};

export default v1_liver;
