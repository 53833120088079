const v1_play: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg width='99' height='99' viewBox='0 0 99 99' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
			<circle cx='49.5' cy='49.5' r='49.5' fill='#EB3A52' />
			<mask
				id='mask0_2_35'
				style={{
					maskType: 'alpha'
				}}
				maskUnits='userSpaceOnUse'
				x='25'
				y='26'
				width='48'
				height='48'>
				<rect x='25' y='26' width='48' height='48' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_2_35)'>
				<path
					d='M43.3 62.4C42.8 62.7333 42.2917 62.75 41.775 62.45C41.2583 62.15 41 61.7 41 61.1V38.6C41 38 41.2583 37.55 41.775 37.25C42.2917 36.95 42.8 36.9667 43.3 37.3L61 48.6C61.4667 48.9 61.7 49.3167 61.7 49.85C61.7 50.3833 61.4667 50.8 61 51.1L43.3 62.4Z'
					fill='white'
				/>
			</g>
		</svg>
	);
};
export default v1_play;
