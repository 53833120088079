const v1_forward: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
		<g>
			<path
				d='M5.64975 7.00033L0.749748 2.10033C0.583081 1.93366 0.502526 1.73644 0.508081 1.50866C0.513637 1.28088 0.599748 1.08366 0.766415 0.916992C0.933081 0.750326 1.1303 0.666992 1.35808 0.666992C1.58586 0.666992 1.78308 0.750326 1.94975 0.916992L7.06642 6.05033C7.19975 6.18366 7.29975 6.33366 7.36642 6.50033C7.43308 6.66699 7.46641 6.83366 7.46641 7.00033C7.46641 7.16699 7.43308 7.33366 7.36642 7.50033C7.29975 7.66699 7.19975 7.81699 7.06642 7.95033L1.93308 13.0837C1.76641 13.2503 1.57197 13.3309 1.34975 13.3253C1.12753 13.3198 0.933081 13.2337 0.766415 13.067C0.599748 12.9003 0.516415 12.7031 0.516415 12.4753C0.516415 12.2475 0.599748 12.0503 0.766415 11.8837L5.64975 7.00033Z'
				fill='#A0AEC0'
			/>
		</g>
	</svg>
);

export default v1_forward;
