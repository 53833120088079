const v1_arrowdropdown: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg width='21' height='22' viewBox='0 0 21 22' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
			<g id='arrow_drop_down'>
				<mask id='mask0_1184_9283' maskUnits='userSpaceOnUse' x='0' y='0' width='21' height='22'>
					<rect id='Bounding box' width='20.619' height='22' fill='#D9D9D9' />
				</mask>
				<g mask='url(#mask0_1184_9283)'>
					<path
						id='arrow_drop_down_2'
						d='M9.70945 13.1087L7.47573 10.7253C7.20367 10.435 7.14281 10.1028 7.29316 9.72845C7.44351 9.35414 7.71198 9.16699 8.09859 9.16699H12.5231C12.9097 9.16699 13.1782 9.35414 13.3285 9.72845C13.4789 10.1028 13.418 10.435 13.146 10.7253L10.9122 13.1087C10.8263 13.2003 10.7332 13.2691 10.633 13.3149C10.5328 13.3607 10.4254 13.3837 10.3108 13.3837C10.1963 13.3837 10.0889 13.3607 9.98867 13.3149C9.88844 13.2691 9.79537 13.2003 9.70945 13.1087Z'
						fill={props.fill || '#111827'}
					/>
				</g>
			</g>
		</svg>
	);
};
export default v1_arrowdropdown;
