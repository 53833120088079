const v1_express: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' {...props} fill='none'>
			<rect width='19' height='19' rx='9.5' fill='#323B49' />
			<mask
				id='mask0_218_21326'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='3'
				y='3'
				width='13'
				height='13'>
				<rect x='3' y='3' width='13' height='13' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_218_21326)'>
				<path
					d='M4.32767 8.72771L6.60267 6.45271C6.72906 6.32632 6.87802 6.23604 7.04954 6.18187C7.22107 6.12771 7.39711 6.11868 7.57767 6.15479L8.28184 6.30375C7.79434 6.88153 7.41066 7.40514 7.13079 7.87458C6.85093 8.34403 6.5801 8.91278 6.31829 9.58083L4.32767 8.72771ZM7.10371 9.96C7.31135 9.31 7.59347 8.69611 7.95007 8.11833C8.30666 7.54055 8.73774 6.99889 9.24329 6.49333C10.0377 5.69889 10.945 5.10531 11.9652 4.7126C12.9853 4.31989 13.9377 4.20028 14.8225 4.35375C14.9759 5.23847 14.8586 6.1909 14.4704 7.21104C14.0822 8.23118 13.4909 9.13847 12.6964 9.93292C12.1999 10.4294 11.6582 10.8605 11.0714 11.2261C10.4846 11.5918 9.86621 11.8784 9.21621 12.086L7.10371 9.96ZM10.8412 8.335C11.0489 8.54264 11.3039 8.64646 11.6063 8.64646C11.9087 8.64646 12.1638 8.54264 12.3714 8.335C12.5791 8.12736 12.6829 7.87233 12.6829 7.5699C12.6829 7.26746 12.5791 7.01243 12.3714 6.80479C12.1638 6.59715 11.9087 6.49333 11.6063 6.49333C11.3039 6.49333 11.0489 6.59715 10.8412 6.80479C10.6336 7.01243 10.5298 7.26746 10.5298 7.5699C10.5298 7.87233 10.6336 8.12736 10.8412 8.335ZM10.462 14.8485L9.59538 12.8579C10.2634 12.5961 10.8344 12.3253 11.3084 12.0454C11.7824 11.7656 12.3082 11.3819 12.886 10.8944L13.0214 11.5985C13.0575 11.7791 13.0485 11.9574 12.9943 12.1334C12.9402 12.3095 12.8499 12.4607 12.7235 12.5871L10.462 14.8485ZM5.19434 11.6933C5.51031 11.3774 5.89399 11.2171 6.34538 11.2126C6.79677 11.2081 7.18045 11.3638 7.49642 11.6798C7.81239 11.9958 7.97038 12.3794 7.97038 12.8308C7.97038 13.2822 7.81239 13.6659 7.49642 13.9819C7.27073 14.2076 6.89382 14.4017 6.36569 14.5642C5.83757 14.7267 5.10857 14.8711 4.17871 14.9975C4.3051 14.0676 4.44954 13.3409 4.61204 12.8173C4.77454 12.2937 4.96864 11.919 5.19434 11.6933Z'
					fill='#FFD023'
				/>
			</g>
		</svg>
	);
};

export default v1_express;
